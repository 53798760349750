import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AuthInterceptor } from 'src/app/core/http-interceptors';
import { environment } from '@dashboard/env';
import { FeedState } from './state/feed.state';
import { RealTimeState } from './state/real-time.state';
import { RolesInterceptor } from './http-interceptors/roles.interceptor';
import { SessionState } from './state/session.state';
import { EnterpriseRolesState } from '../+enterprises/+enterprise/state/enterprise-roles.state';
import { HealthPassConfigState } from '../health-pass/health-pass-config.state';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot([FeedState, RealTimeState, SessionState, EnterpriseRolesState, HealthPassConfigState], {
      developmentMode: environment.NGXS_DEVELOPMENT_MODE
    }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['ngxsSession', 'healthPassConfig']
    }),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // TODO: this is temporary interceptor to mock Permissions in roles requests.
    { provide: HTTP_INTERCEPTORS, useClass: RolesInterceptor, multi: true }
  ]
})
export class CoreModule {}
