import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LiveSafeMatIconRegistry } from '@livesafe/material';
import { delay } from 'rxjs/operators';
import { LIVESAFE_SVGS } from '@dashboard/shared';
import { TipsPubNubService } from './core/services/pubnub-tips.service';
import { HealthPassPubNubService } from './core/services/pubnub-health-pass.service';
import { PubNubService } from './core/services/pubnub.service';

@Component({
  selector: 'dash-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loadingView = false;

  constructor(
    private router: Router,
    private liveSafeMatIconRegistry: LiveSafeMatIconRegistry,
    private $pubnub: PubNubService,
    private $pubnubHealthPass: HealthPassPubNubService,
    private $pubnubTips: TipsPubNubService
  ) {}

  ngOnInit() {
    const pubNubChannels = [this.$pubnubTips.tipsChannel, this.$pubnubHealthPass.healthPassChannel];
    this.$pubnub.subscribe(pubNubChannels);
    this.liveSafeMatIconRegistry.initSvgs(LIVESAFE_SVGS, '../assets/icons');
    this.router.events.pipe(delay(0)).subscribe(e => {
      if (e instanceof NavigationStart) {
        this.loadingView = true;
      } else if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
        this.loadingView = false;
      }
    });
  }

}
