import { TimeagoFormatter, TimeagoIntl } from 'ngx-timeago';
import { strings as englishStrings } from 'ngx-timeago/language-strings/en';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class TimeagoCustomFormatter extends TimeagoFormatter {
  constructor(private intl: TimeagoIntl) {
    super();

    if (!intl.strings) {
      intl.strings = englishStrings;
      intl.changes.next();
    }
  }

  format(then: number): string {
    if (!then) {
      return '';
    }
    const now = Date.now();
    const seconds = Math.round((now - then) / 1000);

    return seconds < 60
      ? 'Now'
      : seconds < 120
        ? this.setFormat(1, <string>this.intl.strings.minute)
        : seconds < 3600
          ? this.setFormat(Math.floor(seconds / 60), <string>this.intl.strings.minutes)
          : seconds < 7200
            ? this.setFormat(1, <string>this.intl.strings.hour)
            : seconds < 86400
              ? this.setFormat(Math.floor(seconds / 3600), <string>this.intl.strings.hours)
              // eslint-disable-next-line no-extra-boolean-cast
              : formatDate(new Date(then), `${!!this.intl.strings.suffixAgo ? 'MMMM' : 'MMM'} d, yyyy`, 'en');
  }

  private setFormat(value: number, format: string) {
    return `${format.replace('about ', '').replace(/%d/g, `${value}`)} ${this.intl.strings.suffixAgo}`;
  }
}
