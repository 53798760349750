import { Icon, IconService } from '../icon/icon.service';
import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector } from '@ngxs/store';
import { IconPicker } from './icon.action';
import { patch } from '@ngxs/store/operators';
import { IconRegistry } from './icon-registry.service';
import { tap } from 'rxjs/operators';
import { environment } from '@dashboard/env';
import { Observable, of } from 'rxjs';

export interface Category {
  name: string;
  icons: Icon[];
}

export class IconStateModel {
  categories: Category[];
}

@State<IconStateModel>({
  name: 'iconpicker',
  defaults: {
    categories: []
  }
})
@Injectable()
export class IconState {

  constructor(
    private $icon: IconService,
    private $iconRegistry: IconRegistry
  ) { }

  @Selector()
  static categories(state: IconStateModel): Category[] { return state.categories.slice().sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); }

  @Action(IconPicker.Init)
  initIcons(ctx: StateContext<IconStateModel>): Observable<Icon[]> {
    const state = ctx.getState();

    if (!state.categories.length) {
      return this.$icon.getIcons()
        .pipe(
          tap((icons: Icon[]) => {
            this.$iconRegistry.initSvgs(icons.map(i => i.name), environment.ICONS_URL);

            const categoriesObj = icons.reduce((prev, icon: Icon) => {
              if (!prev[icon.category]) {
                prev[icon.category] = {
                  name: icon.category,
                  icons: [icon]
                };
              } else {
                prev[icon.category].icons.push(icon);
              }
              return prev;
            }, {});
            ctx.setState(patch({ categories: Object.values(categoriesObj) }));
          })
        );
    } else {
      return of([]);
    }
  }
}
