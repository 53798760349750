/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, EnterpriseAccount, Pagination, Enterprise, OrganizationTree, DashSort, EnterpriseContact, EnterpriseContactComponent } from '@dashboard/core/models';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Subdomain } from '../models/type-tokens';
import { environment } from 'src/environments/environment';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { SessionState } from '../state';
import { ContactOperationValue } from 'src/app/+users/user/contact-component/state/contact-component.state';

export const ACCOUNTS_PAGE_SIZE = 50;

export enum ComponentType {
  Email = 'email',
  Phone = 'phone',
  Name = 'name',
  Verification = 'verification'
}

export interface EnterpriseResponse {
  enterprise: Enterprise;
}

export interface EnterpriseAccountsRequest {
  sort?: string[];
  skip?: number;
  limit?: number;
}

export interface EnterpriseUsersRequest {
  sorts?: string[];
  skip?: number;
  limit?: number;
  filter?: string;
}

export interface EnterpriseUsersResponse {
  data: User[];
  pagination: Pagination;
}

export interface ContactEmail {
  id?: string;
  address: string;
  personal?: string;
}

export interface ContactPersonName {
  id?: string;
  namePrefix?: string;
  givenName: string;
  middleName?: string;
  familyName: string;
  nameSuffix?: string;
  nickname?: string;
}

export interface ContactPhone {
  id?: string;
  number: string;
  regionCode?: string;
}

export interface EnterpriseContactRequest {
  emails?: ContactEmail[];
  personNames?: ContactPersonName[];
  phones?: ContactPhone[];
}

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {
  @SelectSnapshot(SessionState.currentEnterpriseId) currentEnterpriseId: string;
  private url = `${environment.DASHBOARD_APP_API}/enterprises`;
  private urlV2 = `${environment.DASHBOARD_APP_API_V2}/enterprise`;

  constructor(private http: HttpClient) { }

  getEnterprise(subdomain: Subdomain): Observable<Enterprise> {
    return this.http.get<EnterpriseResponse>(`${this.url}/subdomains/${subdomain}`).pipe(pluck('enterprise'));
  }

  getEnterpriseOrganizations(): Observable<OrganizationTree[]> {
    return this.http.get<OrganizationTree[]>(`${this.url}/${this.currentEnterpriseId}/organizations`);
  }

  getEnterpriseUsers(skip = 0, sorts?: DashSort[]): Observable<EnterpriseUsersResponse> {
    const params: EnterpriseUsersRequest = {
      skip,
      limit: ACCOUNTS_PAGE_SIZE
    };

    if (sorts) {
      params.sorts = sorts.map((sort: DashSort) => sort.toString());
    }

    return this.http.get<EnterpriseUsersResponse>(`${this.urlV2}/enterprises/${this.currentEnterpriseId}/contacts`, { params: <HttpParams>params });
  }

  createContact(contact: EnterpriseContactRequest): Observable<EnterpriseContact> {
    if (contact.emails?.length === 1 && contact.emails[0].address === '') {
      contact.emails = [];
    }
    if (contact.phones?.length === 1 && contact.phones[0].number === '') {
      contact.phones = [];
    }

    return this.http.post<EnterpriseContact>(`${this.urlV2}/enterprises/${this.currentEnterpriseId}/contacts`, contact);
  }

  getEnterpriseContact(contactId: string): Observable<EnterpriseContact> {
    return this.http.get<EnterpriseContact>(`${this.urlV2}/contacts/${contactId}`);
  }

  getEnterpriseAccount(accountId: string): Observable<EnterpriseAccount> {
    return this.http.get<EnterpriseAccount>(`${this.urlV2}/accounts/${accountId}`);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createContactComponent(contactComponentType: ComponentType, contactId: string, data: any): Observable<EnterpriseContactComponent> {
    return this.http.post<EnterpriseContactComponent>(
      `${this.urlV2}/contacts/${contactId}/${this.getUrlTypeForComponentType(contactComponentType)}`,
      data
    );
  }

  updateContactComponent(contactComponentType: ComponentType, componentId: string, data: ContactOperationValue): Observable<EnterpriseContactComponent> {
    if (contactComponentType === ComponentType.Name) {
      return this.http.patch<EnterpriseContactComponent>(
        `${this.urlV2}/${this.getUrlTypeForComponentType(contactComponentType)}/${componentId}`,
        data
      );
    } else {
      return this.http.put<EnterpriseContactComponent>(
        `${this.urlV2}/${this.getUrlTypeForComponentType(contactComponentType)}/${componentId}`,
        data
      );
    }
  }

  deleteContactComponent(contactComponentType: ComponentType, componentId: string): Observable<any> {
    return this.http.delete<any>(`${this.urlV2}/${this.getUrlTypeForComponentType(contactComponentType)}/${componentId}`);
  }

  getUrlTypeForComponentType(componentType: ComponentType): string {
    return componentType === ComponentType.Email ? 'emails' :
      componentType === ComponentType.Phone ? 'phones' :
      'personNames';
  }
}
