import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentType } from '@dashboard/core';
import { SnackbarService } from '@livesafe/material';

@Component({
  selector: 'dash-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnChanges {
  @Input() src: string;
  @Input() contentType: ContentType;

  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;
  @HostListener('window:keyup.space')
  toggleVideo(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    if (video.paused) {
      video.controls = true;
      setTimeout(() => {
        video.play();
      }, 100);
    } else {
      video.pause();
      video.controls = false;
    }
  }

  @HostListener('window:keyup.arrowleft', ['$event'])
  @HostListener('window:keyup.arrowright', ['$event'])
  skip($event: KeyboardEvent): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;

    switch ($event.key) {
      case 'ArrowLeft':
        video.currentTime--;
        break;
      case 'ArrowRight':
        video.currentTime++;
        break;
      default:
        break;
    }
  }

  constructor(
    private http: HttpClient,
    private $snackbar: SnackbarService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.src.currentValue && this.videoPlayer) {
      this.videoPlayer.nativeElement.load();
    }
  }

  error(): void {
    this.http.get(this.src, { responseType: 'blob' }).subscribe(
      res => {
        const url = URL.createObjectURL(res);
        this.videoPlayer.nativeElement.src = this.sanitizer.bypassSecurityTrustResourceUrl(url)['changingThisBreaksApplicationSecurity'];
      },
      () => this.$snackbar.error('An error has occurred. Please try again.')
    );
  }
}
