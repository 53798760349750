import { TipStatus } from 'src/app/tips/tips.service';

export enum HealthPassStatus {
  Verified = 'Verified',
  Pending = 'Pending'
}

export type FeedItemStatus = TipStatus | HealthPassStatus;

export class FeedItem {
  id: string;
  isRead: boolean;
  status: FeedItemStatus;
  submitter: string;
  organization: string;
  type: string;
  lastMessage: string;
  lastActivity: number;
}

export class FeedHeaderDetails {
  id: string;
  status: FeedItemStatus;
  created: number | Date;
  submitter: string;
  org: string;
  label: string;
}