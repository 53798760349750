import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { TimeagoIntl } from 'ngx-timeago';
import { strings as englishLongStrings } from 'ngx-timeago/language-strings/en';
import { FeedHeaderDetails, FeedItemStatus } from '../feed.model';

@Component({
  selector: 'dash-feed-detail-header',
  templateUrl: './feed-detail-header.component.html',
  styleUrls: ['./feed-detail-header.component.scss']
})
export class FeedDetailHeaderComponent implements AfterViewInit {
  @Input() details: FeedHeaderDetails;
  @Input() disableStatusToggle = false;
  @Output() statusChange: EventEmitter<FeedItemStatus> = new EventEmitter<FeedItemStatus>();

  constructor(
    private intl: TimeagoIntl,
    private el: ElementRef
  ) { }

  ngAfterViewInit(): void {
    if (this.el.nativeElement.querySelectorAll('.mat-slide-toggle-bar')[0]) {
      this.el.nativeElement.querySelectorAll('.mat-slide-toggle-bar')[0].id = 'open-close-toggle';
    }
  }

  createdAt(): number | Date {
    this.intl.strings = englishLongStrings;
    this.intl.changes.next();

    return this.details.created;
  }

  onOpenCloseToggle(): void {
    this.statusChange.emit(this.details.status);
  }
}
