import { State, Action, StateContext, Selector, SelectorOptions } from '@ngxs/store';
import { Homescreen } from '../homescreen.model';
import { Injectable } from '@angular/core';
import { HomescreenContainer } from './homescreen.actions';
import { removeItem, updateItem, patch, append, iif } from '@ngxs/store/operators';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { v4 as uuidv4 } from 'uuid';
import { TipType, TipTypeConfigurations } from '../../tip-type-config.service';

export class HomescreenContainerStateModel {
  containers: Homescreen.Container[];
  tipTypes: TipType[];
  editingContainer?: Partial<Homescreen.Container>;
  healthPassFields: Homescreen.HealthPassConfig;
  editingHealthPassFields: Homescreen.HealthPassConfig;
  tipTypeConfig: TipTypeConfigurations;
  editingTipTypeConfig?: TipType;
}

export interface CardSignature {
  id: string,
  actionType: Homescreen.ActionType,
  subType: Homescreen.ContainerSubType,
  styleType: Homescreen.StyleType
}

function removeUnusedProperties(container: Partial<Homescreen.Container>): Partial<Homescreen.Container> {
  Object.keys(container).forEach(key => (container[key] === undefined || container[key] === null) && delete container[key]);
  Object.keys(container.style!).forEach(key => (container.style![key] === undefined || container.style![key] === null) && delete container.style![key]);

  return container;
}

export const fields = {
  label: 'Verify',
  hintText: 'Attach media via the buttons below, and add any explanation text here',
  submittedText: 'Your submittal is being reviewed & you’ll receive a notification when it’s been approved',
  bannerText: 'Someone will be with you shortly. You can send additional messages while you wait.',
  chatButtonText: 'Chat with reviewers',
  homeButtonText: 'Home',
  locationEnabled: true,
  anonymityEnabled: false,
  verifiedLabel: 'Verified submittal'
}

const healthPassCardIsDirty = (state: HomescreenContainerStateModel, originalContainer: Homescreen.Container | undefined): boolean => {
  const { editingContainer, editingHealthPassFields, healthPassFields } = state;

  const healthPassCardDirty = !!editingContainer!.styles && !!originalContainer?.styles && 
    Object.keys(editingContainer!.styles!).some(key =>
      editingContainer!.styles![key].style.title !== originalContainer?.styles![key].style.title ||
      editingContainer!.styles![key].style.description !== originalContainer?.styles![key].style.description ||
      editingContainer!.styles![key].style.buttonText !== originalContainer?.styles![key].style.buttonText);

  const healthPassFieldsDirty = editingHealthPassFields.submittedText !== healthPassFields.submittedText ||
    editingHealthPassFields.locationEnabled !== healthPassFields.locationEnabled ||
    editingHealthPassFields.label !== healthPassFields.label ||
    editingHealthPassFields.homeButtonText !== healthPassFields.homeButtonText ||
    editingHealthPassFields.hintText !== healthPassFields.hintText ||
    editingHealthPassFields.chatButtonText !== healthPassFields.chatButtonText ||
    editingHealthPassFields.bannerText !== healthPassFields.bannerText ||
    editingHealthPassFields.anonymityEnabled !== healthPassFields.anonymityEnabled;

  return healthPassCardDirty || healthPassFieldsDirty;
}

const tipsCardIsDirty = (state: HomescreenContainerStateModel): boolean => {
  const { editingTipTypeConfig, tipTypeConfig  } = state;

  const tipTypeIndex = tipTypeConfig.tipConfigs.findIndex(tip => (!!tip.id && !!editingTipTypeConfig?.id && tip.id === editingTipTypeConfig?.id)
    || (!!tip.tempId && !!editingTipTypeConfig?.tempId && tip.tempId === editingTipTypeConfig?.tempId));
  const selectedTipConfig = tipTypeConfig.tipConfigs[tipTypeIndex];

  const tipTypeFieldsDirty = tipTypeIndex !== -1 && (
    editingTipTypeConfig?.chatButtonText !== selectedTipConfig.chatButtonText ||
    editingTipTypeConfig?.hintText !== selectedTipConfig.hintText ||
    editingTipTypeConfig?.iconName !== selectedTipConfig.iconName ||
    editingTipTypeConfig?.label !== selectedTipConfig.label ||
    editingTipTypeConfig?.submitConfirmationText !== selectedTipConfig.submitConfirmationText ||
    editingTipTypeConfig?.suppressAnonymous !== selectedTipConfig.suppressAnonymous ||
    editingTipTypeConfig?.suppressLocation !== selectedTipConfig.suppressLocation ||
    editingTipTypeConfig?.systemMessageText !== selectedTipConfig.systemMessageText ||
    editingTipTypeConfig?.trackLocation !== selectedTipConfig.trackLocation
  );
  return tipTypeFieldsDirty;
}

const defaultCardIsDirty = (editingContainer: Partial<Homescreen.Container>, originalContainer: Homescreen.Container | undefined): boolean => {
  return editingContainer.action?.phone !== originalContainer?.action?.phone ||
    editingContainer.action?.tipTypeId !== originalContainer?.action?.tipTypeId ||
    editingContainer.action?.tracking !== originalContainer?.action?.tracking ||
    editingContainer.action?.url !== originalContainer?.action?.url ||
    editingContainer.style?.description !== originalContainer?.style.description ||
    editingContainer.style?.icon !== originalContainer?.style.icon ||
    editingContainer.style?.iconTint !== originalContainer?.style.iconTint ||
    editingContainer.style?.backgroundHex !== originalContainer?.style.backgroundHex ||
    editingContainer.style?.foregroundHex !== originalContainer?.style.foregroundHex ||
    editingContainer.style?.title !== originalContainer?.style.title;
}

@State<HomescreenContainerStateModel>({
  name: 'homescreenconfig',
  defaults: {
    tipTypes: [],
    containers: [],
    healthPassFields: fields,
    editingHealthPassFields: fields,
    tipTypeConfig: {
      tipSelectTitle: '',
      tipConfigs: []
    }
  }
})
@Injectable()
export class HomescreenContainerState {
  @Selector()
  static containers(state: HomescreenContainerStateModel): Homescreen.Container[] { return state.containers; }

  @Selector()
  static tipTypes(state: HomescreenContainerStateModel): TipType[] { return state.tipTypes; }

  @Selector()
  static tipTypeConfig(state: HomescreenContainerStateModel): TipTypeConfigurations { return state.tipTypeConfig; }

  @Selector()
  static healthPassFieldsPayload(state: HomescreenContainerStateModel): Homescreen.HealthPassConfig { return state.editingHealthPassFields; }

  @Selector()
  static healthPassFields(state: HomescreenContainerStateModel): unknown {
    return {
      label: state.editingHealthPassFields.label,
      hintText: state.editingHealthPassFields.hintText,
      suppressLocation: !state.editingHealthPassFields.locationEnabled,
      submitConfirmationText: state.editingHealthPassFields.bannerText,
      systemMessageText: state.editingHealthPassFields.submittedText,
      chatButtonText: state.editingHealthPassFields.chatButtonText
    }
  }

  @Selector()
  static tipTypeConfigFields(state: HomescreenContainerStateModel): TipType | undefined { return state.editingTipTypeConfig; }

  @Selector()
  static newCard(state: HomescreenContainerStateModel): Homescreen.Container | undefined { return state.containers.find(c => c.id === 'new'); }

  @Selector()
  static editingCard(state: HomescreenContainerStateModel): Partial<Homescreen.Container> | undefined { return state.editingContainer; }

  @Selector()
  static editingCardId(state: HomescreenContainerStateModel): string | undefined { return state.editingContainer?.id; }

  @Selector()
  static editingCardActionType(state: HomescreenContainerStateModel): Homescreen.ActionType | undefined { return state.editingContainer?.actionType; }

  @Selector()
  static editingCardSubType(state: HomescreenContainerStateModel): Homescreen.ContainerSubType | undefined { return state.editingContainer?.subType; }

  @Selector()
  static editingCardStyleType(state: HomescreenContainerStateModel): Homescreen.StyleType | undefined { return state.editingContainer?.styleType; }

  @Selector()
  static isPresetTipCard(state: HomescreenContainerStateModel): boolean | undefined {
    return state.editingContainer?.subType === Homescreen.ContainerSubType.MessageOrgSecurity || state.editingContainer?.subType === Homescreen.ContainerSubType.SuspiciousActivity || state.editingContainer?.subType === Homescreen.ContainerSubType.SafeWalkWithSecurity;
  }

  @SelectorOptions({
    injectContainerState: false
  })
  @Selector([
    HomescreenContainerState.editingCardId,
    HomescreenContainerState.editingCardActionType,
    HomescreenContainerState.editingCardSubType,
    HomescreenContainerState.editingCardStyleType
  ])
  static editingCardSignature(id: string, actionType: string, subType: string, styleType: string): unknown {
    return { id, actionType, subType, styleType };
  }

  @Selector()
  static untouchedEditingCard(state: HomescreenContainerStateModel): Homescreen.Container | undefined { return state.containers.find(c => c.id === state.editingContainer?.id); }

  @Selector()
  static editingCardIsDirty(state: HomescreenContainerStateModel): boolean {
    const { editingContainer } = state;
    if (!editingContainer) {
      return false;
    }

    const originalContainer = state.containers.find(c => c.id === editingContainer.id);
    switch (editingContainer.actionType) {
      case Homescreen.ActionType.HealthPass:
        return defaultCardIsDirty(editingContainer, originalContainer) || healthPassCardIsDirty(state, originalContainer);

      case Homescreen.ActionType.ReportTips:
        return defaultCardIsDirty(editingContainer, originalContainer) || tipsCardIsDirty(state);

      default:
        return defaultCardIsDirty(editingContainer, originalContainer);
    }
  }

  @Action(HomescreenContainer.Init)
  initContainers(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.Init): void {
    ctx.setState(patch({ containers: this.normalizeContainers(action.containers) }));
  }

  @Action(HomescreenContainer.InitTipTypes)
  initTipTypes(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.InitTipTypes): void {
    ctx.setState(patch({ tipTypes: action.tipTypes }));
  }

  @Action(HomescreenContainer.InitTipTypeConfigs)
  initTipTypeConfig(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.InitTipTypeConfigs): void {
    ctx.setState(patch({ tipTypeConfig: action.tipTypeConfig }));
  }

  @Action(HomescreenContainer.Add)
  addContainer(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.Add): void {
    ctx.setState(patch({ containers: append([action.container]) }));
  }

  @Action(HomescreenContainer.Edit)
  editContainer(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.Edit): void {
    const state = ctx.getState();
    const homescreen = {
      tipTypes: [...state.tipTypes],
      containers: [...state.containers],
      editingContainer: removeUnusedProperties(JSON.parse(JSON.stringify(action.container))),
      healthPassFields: { ...state.healthPassFields },
      editingHealthPassFields: { ...state.editingHealthPassFields },
      tipTypeConfig: { ...state.tipTypeConfig }
    };
    if (!!action.container.action?.tipTypeId) {
      const editingTip = state.tipTypeConfig.tipConfigs.find(tip => tip.tipTypeId === action.container.action?.tipTypeId);
      if (!!editingTip) {
        homescreen['editingTipTypeConfig'] = editingTip;
      }
    }
    ctx.setState(homescreen);
  }

  @Action(HomescreenContainer.Delete)
  deleteContainer(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.Delete): void {
    ctx.setState(patch({ containers: removeItem<Homescreen.Container>(c => c!.id === action.container.id) }));
  }

  @Action(HomescreenContainer.UpdateDisplayOrder)
  updateDisplayOrder(ctx: StateContext<HomescreenContainerStateModel>, { previousIndex, currentIndex }: { previousIndex: number, currentIndex: number}): void {
    const array = [...ctx.getState().containers];
    moveItemInArray(array, previousIndex, currentIndex);

    ctx.setState(patch({ containers: array }));
  }

  @Action(HomescreenContainer.StopEditing)
  stopEditingContainer(ctx: StateContext<HomescreenContainerStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      tipTypes: [...state.tipTypes],
      containers: [...state.containers],
      healthPassFields: { ...state.healthPassFields },
      editingHealthPassFields: { ...state.editingHealthPassFields },
      tipTypeConfig: { ...state.tipTypeConfig }
    });
  }

  @Action(HomescreenContainer.Update)
  updateContainer(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.Update): void {
    const newContainer: Homescreen.Container = JSON.parse(JSON.stringify(action.newContainer));
    const uuid = uuidv4();

    if (newContainer.id === 'new') {
      newContainer.id = uuid;
    }

    ctx.setState(
      patch({
        containers: updateItem<Homescreen.Container>(c => c!.id === action.container.id, newContainer),
        editingContainer: patch({ id: newContainer.id })
      })
    );
  }

  @Action(HomescreenContainer.ResetEditingContainer)
  resetEditingContainer(ctx: StateContext<HomescreenContainerStateModel>): void {
    const state = ctx.getState();
    const container = state.containers.find(c => c.id === state.editingContainer?.id);

    const resetState = {
      editingContainer: removeUnusedProperties(JSON.parse(JSON.stringify(container))),
      editingHealthPassFields: state.healthPassFields
    }

    if (!!state.editingTipTypeConfig) {
      const editingTipTypeConfig = state.tipTypeConfig.tipConfigs.find(tip => tip.tipTypeId === state.editingTipTypeConfig?.tipTypeId);
      resetState['editingTipTypeConfig'] = editingTipTypeConfig;
    }

    ctx.setState(patch(resetState));
  }

  @Action(HomescreenContainer.UpdateEditingContainer)
  updateEditingContainer(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.UpdateEditingContainer): void {
    const state = ctx.getState();
    ctx.setState(
      patch({
        editingContainer: removeUnusedProperties({...state.editingContainer, ...action.container})
      })
    );
  }

  @Action(HomescreenContainer.InitHealthPassFields)
  initHealthPassFields(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.InitHealthPassFields): void {
    ctx.setState(patch({
      editingHealthPassFields: action.fields,
      healthPassFields: action.fields
    }));
  }

  @Action(HomescreenContainer.UpdateHealthPassFields)
  updateHealthPassFields(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.UpdateHealthPassFields): void {
    ctx.setState(
      patch({
        editingHealthPassFields: {
          label: action.fields.label,
          hintText: action.fields.hintText,
          submittedText: action.fields.systemMessageText,
          bannerText: action.fields.submitConfirmationText,
          chatButtonText: action.fields.chatButtonText,
          homeButtonText: 'Home',
          locationEnabled: !action.fields.suppressLocation,
          anonymityEnabled: false,
          verifiedLabel: action.fields.verifiedLabel
        }
      })
    );
  }

  @Action(HomescreenContainer.UpdateTipTypeConfigFields)
  updateTipTypeConfigFields(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.UpdateTipTypeConfigFields): void {
    ctx.setState(patch({ editingTipTypeConfig: action.fields }));
  }

  @Action(HomescreenContainer.SelectTipType)
  selectTipType(ctx: StateContext<HomescreenContainerStateModel>, action: HomescreenContainer.SelectTipType): void {
    const selectedTipConfigs = ctx.getState().tipTypeConfig.tipConfigs;
    const editingTipType = ctx.getState().editingTipTypeConfig;
    const tipConfig = selectedTipConfigs.find(tc => tc.tipTypeId === action.tipId);
    
    if (!editingTipType || editingTipType.tipTypeId !== action.tipId) {
      if (!!tipConfig) {
        ctx.setState(patch({ editingTipTypeConfig: tipConfig }));
      } else {
        const tipTypes = ctx.getState().tipTypes;
        const tipType = tipTypes.find(tip => tip.id === action.tipId);
  
        if (!!tipType) {
          const sameTipTypes = selectedTipConfigs.filter(tip => tip.tipTypeId === tipType.id);
          // Add tempId to be able to identify dirty tip cards that don't yet have an id
          const newTipConfig = {...tipType, tipTypeId: tipType?.id, tempId: `${tipType.id}-${sameTipTypes.length}`};
          delete newTipConfig['id'];
          ctx.setState(patch({
            editingTipTypeConfig: newTipConfig,
            tipTypeConfig: patch({ tipConfigs: append([newTipConfig]) })
          }));
        }
      }
    }
  }

  @Action(HomescreenContainer.UpdateTipTypes)
  updateTipTypes(ctx: StateContext<HomescreenContainerStateModel>): void {
    const { editingTipTypeConfig, tipTypeConfig } = ctx.getState();
    const tipConfigExists = tipTypeConfig.tipConfigs.findIndex(tip => tip.tipTypeId === editingTipTypeConfig?.tipTypeId);

    if (!!editingTipTypeConfig) {
      ctx.setState(patch({ tipTypeConfig:
        iif<TipTypeConfigurations>(tipConfigExists !== -1,
          patch({ tipConfigs: updateItem(tipConfigExists, editingTipTypeConfig)}),
          patch({ tipConfigs: append([editingTipTypeConfig])})
        )
      }));
    }
  }

  private normalizeContainers(containers: Homescreen.Container[]): Homescreen.Container[] {
    return containers.map(container => {
      if (container.actionType !== Homescreen.ActionType.HealthPass) {
        return container;
      }
      return {
        ...container,
        styleType: Homescreen.StyleType.Type1,
        style: {
          graphic: Homescreen.Graphic.HealthPass,
          title: 'Verify'
        }
      }
    });
  }
}
