import { TipStatus, TipSummary } from 'src/app/tips/tips.service';

export namespace TipsFeed {
  export class SetFeed {
    static readonly type = '[Tip Feed] Set feed';
    constructor(public payload: TipSummary[]) {}
  }

  export class AppendToFeed {
    static readonly type = '[Feed] Append to feed';
    constructor(public payload: TipSummary[]) {}
  }

  export class UpdateFeedItems {
    static readonly type = '[Feed] Update feed items';
    constructor(public payload: TipSummary[]) {}
  }

  export class MarkTipRead {
    static readonly type = '[Feed] Mark tip read';
    constructor(public tipId: string) {}
  }

  export class UpdatePagination {
    static readonly type = '[Feed] Update pagination';
    constructor(public moreItems: boolean) {}
  }

  export class SetError {
    static readonly type = '[Feed] Error';
    constructor(public error: boolean) {}
  }

  export class UpdateTipStatus {
    static readonly type = '[Feed] Update tip status';
    constructor(public tipId: string, public status: TipStatus) {}
  }
}
