import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { CreateOrganizationModalComponent } from 'src/app/organizations/create-organization-modal/create-organization-modal.component';
import {
  OrganizationId,
  OrganizationService,
  OrganizationTree
} from '@dashboard/core';
import { SnackbarService } from '@livesafe/material';
import { hideFeature } from '@dashboard/core';

@Component({
  selector: 'dash-org-tree-node',
  templateUrl: './org-tree-node.component.html',
  styleUrls: ['./org-tree-node.component.scss']
})
export class OrgTreeNodeComponent {
  @Input() org: OrganizationTree;
  @Input() mode: 'readonly' | 'edit' | 'add' = 'readonly';
  @Input() selectedOrgs: OrganizationId[] = [];

  @Output() toggleOrgSelected: EventEmitter<OrganizationId> = new EventEmitter<OrganizationId>();
  @Output() orgEdit: EventEmitter<OrganizationId> = new EventEmitter<OrganizationId>();

  children: OrganizationTree[] = [];

  childrenLoading = false;
  childAdded = false;

  dialog: MatDialogRef<CreateOrganizationModalComponent>;

  hideOrgCrud = hideFeature.orgManagementCrud;

  constructor(
    private $dialog: MatDialog,
    private $organization: OrganizationService,
    private $snackbar: SnackbarService
  ) {}

  toggleChildren(expanded?: boolean): void {
    if (!this.isExpanded() && (expanded || this.hasChildren())) {
      this.childrenLoading = true;
      this.$organization
        .getOrganizationChildren(this.org.id)
        .pipe(finalize(() => (this.childrenLoading = false)))
        .subscribe(
          (children: OrganizationTree[]) => {
            this.children = children;
          },
          () => {
            this.$snackbar.error('An error has occurred. Please try again.');
            this.children = [];
          }
        );
    } else if (expanded === undefined) {
      this.children = [];
    }
  }

  hasChildren(): boolean {
    return (!!this.org.children && !!this.org.children.length) || !!this.children.length;
  }

  isExpanded(): boolean {
    return !!this.children.length;
  }

  onToggleOrgSelected(orgId: OrganizationId): void {
    if (this.isSelected(orgId)) {
      this.selectedOrgs = this.selectedOrgs.filter(_orgId => _orgId !== orgId);
    } else {
      this.selectedOrgs = [...this.selectedOrgs, orgId];
    }

    this.toggleOrgSelected.emit(orgId);
  }

  isSelected(orgId: OrganizationId): boolean {
    return this.selectedOrgs.indexOf(orgId) > -1;
  }

  onAddChild(): void {
    this.toggleChildren(true);

    this.dialog = this.$dialog.open(CreateOrganizationModalComponent, {
      width: '900px',
      data: { parentOrgId: this.org.id },
      closeOnNavigation: false
    });

    this.dialog.afterClosed().subscribe((result: undefined | OrganizationTree) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!result) {
        this.childAdded = true;
        this.children = [...this.children, result];
      }
    });
  }
}
