export const LIVESAFE_SVGS = [
  'broadcast',
  'chatbot',
  'emergency',
  'make-admin',
  'media',
  'move',
  'tip',
  'lock',
  'connect-conversation-created',
  'connect',
  'health-pass'
];
