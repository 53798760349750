export namespace Homescreen {

  export enum ContainerType {
    Carousel = 'carousel',
    Card = 'card'
  }

  export enum ContainerSubType {
    Resources = 'resources',
    SafeRidePhone = 'safe-ride-phone',
    CallOrgSecurity = 'call-org-security',
    MessageOrgSecurity = 'message-org-security',
    SuspiciousActivity = 'suspicious-activity',
    EmergencyCall = 'emergency-call',
    SafeWalkWithSecurity = 'safe-walk-with-security'
  }

  export interface ContainerCard {
    id: string;
    type: ContainerType;
    actionType?: ActionType;
    action: unknown;
    styleType: StyleType;
    style: Style;
  }

  export enum Graphic {
    Connect = 'connect',
    Emergency = 'emergency',
    Resources = 'resources',
    SafeRide = 'safe-ride',
    SafetyMap = 'safety-map',
    SafeWalk = 'safe-walk',
    Chatbot = 'chatbot',
    CustomTipType = 'custom-tip-type',
    SuspiciousActivity = 'suspicious-activity',
    HealthPass = 'health-pass',
    HealthPassVerified = 'health-pass-verified',
    HealthPassNotSubmitted = 'health-pass-not-submitted',
    HealthPassPending = 'health-pass-pending',
    SafeWalkWithSecurity = 'safe-walk-with-security'
  }

  export enum ActionType {
    Connect = 'connect',
    URL = 'url',
    Call = 'call',
    Resources = 'resources',
    SafetyMap = 'safety-map',
    Chatbot = 'chatbot',
    EmergencyScreen = 'emergency-screen',
    ReportTips = 'report-tips',
    GoSafe = 'go-safe',
    SafeRideEvent = 'safe-ride-event',
    SafeWalk = 'safe-walk',
    HealthPass = 'health-pass'
  }

  export enum StyleType {
    Type1 = 'type-1',
    Type2 = 'type-2',
    Type3 = 'type-3',
    Type4 = 'type-4',
    Type5 = 'type-5',
    CarouselType1 = 'carousel-type-1'
  }

  export interface Action {
    phone?: string;
    tracking?: boolean;
    url?: string;
    tipTypeId?: string;
  }

  export interface Style {
    buttonText?: string;
    title: string;
    description?: string;
    icon?: string;
    iconTint?: string;
    graphic?: Graphic;
    backgroundHex?: string;
    foregroundHex?: string;
  }

  export interface HealthPassConfig {
    label: string;
    hintText: string;
    submittedText: string;
    bannerText: string;
    chatButtonText: string;
    homeButtonText: string;
    locationEnabled: boolean;
    anonymityEnabled: boolean;
    verifiedLabel: string;
  }

  export interface Container {
    id: string;
    type: ContainerType;
    subType?: ContainerSubType;
    cards?: ContainerCard[];
    actionType?: ActionType;
    action?: Action;
    styleType: StyleType;
    style: Style;
    styles?: StateCard[];
  }

  export interface StateCard {
    styleType: StyleType;
    cardStateId: string;
    style: Style;
    stateName?: string;
  }
}
