import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { FocusService } from '@dashboard/core';

@Directive({
  selector: '[dashFocusOnInit]'
})
export class FocusOnInitDirective implements OnInit {
  @Input() focusOnInitEnabled = true;

  constructor(private host: ElementRef, private $focus: FocusService) { }

  ngOnInit() {
    if (this.focusOnInitEnabled) {
      this.$focus.focus(<HTMLElement>this.host.nativeElement);
    }
  }
}
