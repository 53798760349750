import { Component, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { SortOrder } from '@dashboard/core';
import { MatDialog } from '@angular/material/dialog';
import { UIUser } from 'src/app/core/services/healthpass.service';

@Component({
  selector: 'dash-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements AfterViewInit, OnChanges, OnDestroy {

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  @Input() showBadges = true;
  @Input() showHealthPassStatus = false;
  @Input() users$: Observable<UIUser[]>;
  @Input() sortField: string;
  @Input() sortDir: SortOrder;
  @Input() displayColumns: string[] = ['mediumFullName', 'emailAddress', 'phoneNumber'];
  @Input() totalUserCount: number;
  @Input() filterText: string;
  @Output() selectUser: EventEmitter<UIUser> = new EventEmitter<UIUser>();
  @Output() sortChanged: EventEmitter<{
    sortField: string,
    sortDir: SortOrder,
    pageIndex: number
  }> = new EventEmitter<{
    sortField: string,
    sortDir: SortOrder,
    pageIndex: number
  }>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected $dialog: MatDialog,
    protected $store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterText?.currentValue) {
      this.resetPaging();
    }
  }
  
  ngAfterViewInit() {
    merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => {
        this.sortChanged.emit({
          sortField: this.sort.active,
          sortDir: <SortOrder>this.sort.direction,
          pageIndex: this.paginator.pageIndex
        });
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }
}
