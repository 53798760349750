import { Component, Input } from '@angular/core';

@Component({
  selector: 'dash-groups-loading',
  template: `
    <div *ngIf="hasHeader" class="header loading"></div>
    <div *ngIf="hasTitle" class="title loading"></div>
    <div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
      <div class="card loading"></div>
    </div>
  `,
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() hasHeader = false;
  @Input() hasTitle = false;
}
