import { Injectable } from '@angular/core';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { compose, iif, insertItem, patch, removeItem } from '@ngxs/store/operators';
import { AmplifyConfig } from '@dashboard/core/guards';
import { Session } from './session.actions';
import { Amplify } from '@aws-amplify/core';
import { AccountPermissionResponse, ContextualRole, PermissionService, Permission, SystemGeneratedRole } from '../services/permission.service';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

export interface Domain {
  name: string;
}

export interface EnterpriseSession {
  name: string;
  id: string;
  domains: Domain[];
  config: AmplifyConfig;
}

export interface SessionAccount {
  id: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string;
}

export enum UserRole {
  UserManagement = 'UserManagement',
  AdminWebAppAccess = 'AdminWebAppAccess',
  AlertManagement = 'AlertManagement',
  EnterpriseManagement = 'EnterpriseManagement',
  ConnectManagement = 'ConnectManagement',
  VerifyManagement = 'VerifyManagement'
}

export class SessionStateModel {
  currentAccount?: SessionAccount | undefined;
  currentEnterpriseId?: string;
  enterpriseSessions: EnterpriseSession[];
  permissions?: AccountPermissionResponse;
  accountPermissions?: UserRole[];
}

@State<SessionStateModel>({
  name: 'ngxsSession',
  defaults: {
    enterpriseSessions: []
  }
})
@Injectable()
export class SessionState {
  constructor(
    private $permission: PermissionService,
    private $store: Store,
    private $permissions: NgxPermissionsService,
    private router: Router
  ) {}

  // Top-level queries
  @Selector()
  static currentEnterpriseId(state: SessionStateModel): string | undefined { return state.currentEnterpriseId; }

  @Selector()
  static enterpriseSessions(state: SessionStateModel): EnterpriseSession[] { return state.enterpriseSessions; }

  @Selector()
  static currentAccount(state: SessionStateModel): SessionAccount | undefined { return state.currentAccount; }

  @Selector()
  static permissions(state: SessionStateModel): AccountPermissionResponse | undefined { return state.permissions; }

  @Selector()
  static accountPermissions(state: SessionStateModel): UserRole[] | undefined { return state.accountPermissions; }

  // Compound queries
  @SelectorOptions({ injectContainerState: false })
  @Selector([
    SessionState.currentEnterpriseId,
    SessionState.enterpriseSessions
  ])
  static currentEnterprise(currentEnterpriseId: string, enterpriseSessions: EnterpriseSession[]): EnterpriseSession | null {
    return enterpriseSessions.find(e => e.id === currentEnterpriseId) || null;
  }
  
  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.accountPermissions])
  static hasHomescreenConfigPermission(permissions: UserRole[]): boolean {
    return permissions.includes(UserRole.EnterpriseManagement);
  }  

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.permissions])
  static assignments(permissions: AccountPermissionResponse): ContextualRole[] | undefined { return permissions.assignments; }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.assignments])
  static canAssignEnterpriseOwner(assignments: ContextualRole[]): boolean { return !!assignments.find(r => r.role.key === SystemGeneratedRole.EnterpriseOwner); }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.currentEnterprise])
  static currentEnterpriseName(currentEnterprise: EnterpriseSession): string { return currentEnterprise?.name; }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.currentAccount])
  static currentAccountId(account: SessionAccount): string { return account?.id; }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.currentAccountGivenName, SessionState.currentAccountFamilyName])
  static currentAccountMediumName(givenName: string, familyName: string): string {
    return `${givenName} ${familyName}`.trim();
  }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.currentAccount])
  static currentAccountFamilyName(account: SessionAccount): string { return account?.familyName || ''; }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.currentAccount])
  static currentAccountGivenName(account: SessionAccount): string { return account?.givenName || ''; }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.currentAccount])
  static currentAccountEmail(account: SessionAccount): string { return account?.email || ''; }

  @SelectorOptions({ injectContainerState: false })
  @Selector([SessionState.currentAccount])
  static currentAccountPhoneNumber(account: SessionAccount): string { return account?.phoneNumber || ''; }

  @SelectorOptions({ injectContainerState: false })
  @Selector([
    SessionState.currentAccountMediumName,
    SessionState.currentAccountEmail,
    SessionState.currentAccountPhoneNumber
  ])
  static currentAccountUsername(mediumName: string, email: string, phoneNumber: string): string {
    return mediumName || email || phoneNumber || 'Profile';
  }

  @Action(Session.SetCurrentAccount)
  setCurrentAccound(ctx: StateContext<SessionStateModel>, action: Session.SetCurrentAccount): void {
    ctx.setState(
      patch({
        currentAccount: action.account
      })
    );
  }

  @Action(Session.AddEnterpriseSession)
  addEnterpriseSession(ctx: StateContext<SessionStateModel>, action: Session.AddEnterpriseSession): void {
    ctx.setState(
      compose(
        iif(
          (c) => !c?.enterpriseSessions.find(eS => eS.id === action.enterpriseSession.id),
          patch({
            enterpriseSessions: insertItem({
              id: action.enterpriseSession.id,
              name: action.enterpriseSession.name,
              domains: action.enterpriseSession.domains,
              config: action.enterpriseSession.config
            })
          })
        ),
        patch({
          currentEnterpriseId: action.enterpriseSession.id
        })
      )
    );
  }

  @Action(Session.ActivateEnterpriseSession)
  activateEnterpriseSession(ctx: StateContext<SessionStateModel>, action: Session.ActivateEnterpriseSession): void {
    ctx.setState(
      patch({
        currentEnterpriseId: action.enterpriseSession.id
      })
    );

    Amplify.configure(action.enterpriseSession.config);
  }

  @Action(Session.RemoveEnterpriseSession)
  removeEnterpriseSession(ctx: StateContext<SessionStateModel>, action: Session.RemoveEnterpriseSession): void {
    ctx.setState(
      compose(
        patch({
          enterpriseSessions: removeItem<EnterpriseSession>(enterpriseSession => enterpriseSession?.id === action.enterpriseId)
        }),
        iif(
          (c) => c?.currentEnterpriseId === action.enterpriseId,
          patch({
            currentEnterpriseId: ''
          })
        )
      )
    );
  }

  @Action(Session.RemoveAllEnterpriseSessions)
  removeAllEnterpriseSessions(ctx: StateContext<SessionStateModel>): void {
    ctx.patchState({
      enterpriseSessions: [],
      currentAccount: undefined,
      currentEnterpriseId: ''
    });
  }

  @Action(Session.FetchPermissions)
  fetchPermissions(ctx: StateContext<SessionStateModel>): Observable<AccountPermissionResponse> {
    return this.$permission.getPermissionByAccountId(this.$store.selectSnapshot(SessionState.currentAccountId))
      .pipe(
        tap((res: AccountPermissionResponse) => ctx.patchState({ permissions: res }))
      );
  }

  @Action(Session.GetPermissions)
  getPermissions(ctx: StateContext<SessionStateModel>): Observable<Permission[]> {
    return this.$permission.getPermissionsById()
      .pipe(
        tap((res: Permission[]) => {
          const accountPermissions = res.filter(p => p.granted).map(f => f.name) as UserRole[];
          this.$permissions.loadPermissions(accountPermissions);
          ctx.patchState({ accountPermissions });
        }),
        finalize(() => {
          if (!ctx.getState().accountPermissions!.includes(UserRole.AdminWebAppAccess)) {
            this.router.navigate(['e', this.$store.selectSnapshot(SessionState.currentEnterpriseId), 'unauthorized']);
          }
        })
      );
  }
}
