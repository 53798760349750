import { Injectable } from '@angular/core';
import { Tips } from '../state/real-time.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RealTimeMessage } from './pubnub.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { SessionState } from '../state';

export class TipsRealTimeMessage implements RealTimeMessage {
  message: string;
  timetoken: string;
  tipId: string;
}

@Injectable({
  providedIn: 'root'
})
export class TipsPubNubService {
  @SelectSnapshot(SessionState.currentEnterpriseId) currentEnterpriseId: string;

  @Dispatch()
  tipChatSent(tipMessage: TipsRealTimeMessage): Tips.ChatSent {
    return new Tips.ChatSent(tipMessage);
  }

  @Dispatch()
  tipCreated(tipMessage: TipsRealTimeMessage): Tips.Created {
    return new Tips.Created(tipMessage);
  }

  @Dispatch()
  tipStatusChanged(tipMessage: TipsRealTimeMessage): Tips.StatusChanged {
    return new Tips.StatusChanged(tipMessage);
  }

  get tipsChannel(): string {
    return `tips-${this.currentEnterpriseId}`;
  }
}
