import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@dashboard/env';
import { EnterpriseSession } from '@dashboard/core/state';

export interface AuthConfig {
  enterprise: EnterpriseSession,
  auth0: {
    client_id: string;
    domain: string;
    issuer: string;
    useRefreshTokens: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Amplify: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private $http: HttpClient) { }

  getAuthConfigByEnterprise(enterpriseId: string): Observable<AuthConfig> {
    return this.$http.get<AuthConfig>(`${environment.DASHBOARD_APP_API}/authentication/enterprises/${enterpriseId}/clients/${environment.AUTH_CLIENT_ID}/settings`);
  }

  getAuthConfigBySubdomain(subdomain: string): Observable<AuthConfig> {
    return this.$http.get<AuthConfig>(`${environment.DASHBOARD_APP_API}/authentication/enterprise-domains/${subdomain}/clients/web-dashboard/settings`);
  }

  getLoginByEmail(email: string): Observable<unknown> {
    return this.$http.post<unknown>(`${environment.DASHBOARD_APP_API}/find-my-enterprise/searches`, {
      terms: email
    });
  }
}
