/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class Permission {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
  constructor() {
    this.id = Math.random().toString();
    this.name = `Permission ${this.id}`;
    this.description = 'Test Permission';
    this.enabled = Math.random() >= 0.5;
  }
}

class PermissionGroup {
  id: string;
  name: string;
  description: string;
  permissions: Permission[];
  constructor() {
    this.id = Math.random().toString();
    this.name = `Permission Group ${this.id}`;
    this.description = 'Test Permission Group';
    this.permissions = [new Permission(), new Permission(), new Permission()];
  }
}

@Injectable()
export class RolesInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/api/v1/authorizations/enterprises/')) {
      return next.handle(request)
        .pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              const modified = {
                ...event.body,
                roles: (event.body.roles || []).map(role => {
                  role.permissions = [
                    new PermissionGroup(),
                    new PermissionGroup(),
                  ];

                  return role;
                })
              };

              return event.clone({ body: modified });
            }

            return event;
          })
        );
    }

    return next.handle(request);
  }
}
