import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IMedia {
  type: string;
  src: string;
}

@Component({
  selector: 'dash-livesafe-large-thumbnail',
  templateUrl: 'large-thumbnail.component.html',
  styleUrls: ['large-thumbnail.component.scss']
})

export class LargeThumbnailComponent {
  get selectedIndex(): number {
    return this._selectedIndex;
  }
  private _selectedIndex = 0;

  @Input() media: IMedia[] = [];
  @Input() enableDelete = false;
  @Input() showSpinner = false;
  @Output() selected: EventEmitter<number> = new EventEmitter();
  @Output() delete: EventEmitter<number> = new EventEmitter();

  onMediaClick(index: number): void {
    this.selected.emit(index);
  }

  onDeleteClick(index: number): void {
    if (this.enableDelete && this.media.length > 0) {
      this.delete.emit(index);
    }
  }

  swipeBack(): void {
    if (this.canSwipeBack()) {
      this._selectedIndex -= 1;
    }
  }

  swipeNext(): void {
    if (this.canSwipeNext()) {
      this._selectedIndex++;
    }
  }

  canSwipeBack(): boolean {
    return this.selectedIndex !== 0;
  }

  canSwipeNext(): boolean {
    return this.selectedIndex !== (this.media.length - 1);
  }
}
