import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account, Email, Phone, RoleId } from '@dashboard/core/models';
import { environment } from 'src/environments/environment';

import { AccountId, OrganizationId } from '../models/type-tokens';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { SessionState } from '../state';

export interface AccountResponse {
  account: Account;
}

export interface CreateAccountRequest {
  enterpriseId?: string;
  givenName: string;
  familyName: string;
  email: Email;
  organizations: OrganizationId[];
  roles: RoleId[];
}

export interface UpdateAccountRequest {
  accountId: AccountId;
  givenName: string;
  familyName: string;
  email: Email;
  phone?: Phone;
  organizations?: OrganizationId[];
  roles?: RoleId[];
}

export interface RegisterAccountRequest {
  onboardingToken: string;
  updatedFirstName?: string;
  updatedLastName?: string;
  updatedPassword: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  @SelectSnapshot(SessionState.currentEnterpriseId) currentEnterpriseId: string;
  constructor(private http: HttpClient) { }

  getAccount(id: AccountId): Observable<Account> {
    const url = `${environment.DASHBOARD_APP_API}/accounts/${id}`;

    return this.http.get<AccountResponse>(url).pipe(map(res => res.account));
  }

  createAccount(request: CreateAccountRequest): Observable<Account> {
    const url = `${environment.DASHBOARD_APP_API}/accounts`;

    request.enterpriseId = this.currentEnterpriseId;

    return this.http.post<Account>(url, request);
  }

  updateAccount(request: UpdateAccountRequest): Observable<Account> {
    const url = `${environment.DASHBOARD_APP_API}/accounts/${request.accountId}`;

    return this.http.post<Account>(url, request);
  }
}
