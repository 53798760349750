import { Component, Input } from '@angular/core';
import { TimeagoIntl } from 'ngx-timeago';
import { strings as englishLongStrings } from 'ngx-timeago/language-strings/en';

@Component({
  selector: 'dash-system-message-chat',
  templateUrl: './system-message-chat.component.html',
  styleUrls: ['./system-message-chat.component.scss']
})
export class SystemMessageChatComponent {
  @Input() message: string;
  @Input() created: Date;

  constructor(private intl: TimeagoIntl) { }

  createdAt(): Date {
    this.intl.strings = englishLongStrings;
    this.intl.changes.next();

    return this.created;
  }
}
