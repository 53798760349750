import { Component, Input, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Attachment } from '@dashboard/core';
import { MediaService, IMedia } from '@livesafe/common';
import { OverlayService } from '@livesafe/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dash-connect-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.scss']
})
export class ConnectMediaCarouselComponent implements OnInit, OnDestroy {
  @Input() attachments: Attachment[] = [];
  @Input() baseUrl: string;
  @Input() set selectedIndex(index: number) {
    this._selectedIndex = index;
    this.selected = this.attachments[index];
  }
  get selectedIndex(): number {
    return this._selectedIndex;
  }
  private _selectedIndex: number;
  @Input() thumbnailMedia: IMedia[] = [];
  selected: Attachment;
  @Input() delete$: Subject<{
    baseUrl: string,
    mediaId: string,
    index: number
  }>;
  @Input() showSpinner$: Subject<boolean>;
  @Input() update$: Subject<number>;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private $overlay: OverlayService, public $media: MediaService) {}

  ngOnInit(): void {
    if (!!this.update$) {
      this.update$.pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(index => {
        const updatedAttachments = [...this.attachments];
        const updatedThumbnails = [...this.thumbnailMedia];
        updatedAttachments.splice(index, 1);
        updatedThumbnails.splice(index, 1);
  
        if (updatedThumbnails.length === 0) {
          this.close();
        } else {
          this.attachments = updatedAttachments;
          this.thumbnailMedia = updatedThumbnails;
          this.selectedIndex = index < updatedThumbnails.length ? index : index - 1;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:keyup.esc')
  close(): void {
    this.$overlay.close(ConnectMediaCarouselComponent);
  }

  select(index: number): void {
    this.selectedIndex = index;
  }

  hasPrevious(): boolean {
    return this.selectedIndex !== 0;
  }

  @HostListener('window:keyup.arrowleft')
  previous(): void {
    if (this.hasPrevious()) {
      this.selectedIndex--;
    } else {
      this.selectedIndex = this.attachments.length - 1;
    }
  }

  hasNext(): boolean {
    return this.selectedIndex !== this.attachments.length - 1;
  }

  @HostListener('window:keyup.arrowright')
  next(): void {
    if (this.hasNext()) {
      this.selectedIndex++;
    } else {
      this.selectedIndex = 0;
    }
  }

  getMediaUrl(mediaId: string): string {
    return `${this.baseUrl}/${mediaId}`;
  }

  onDeleteClick(index: number): void {
    if (this.delete$) {
      this.delete$.next({
        baseUrl: this.baseUrl,
        mediaId: this.selected.mediaId,
        index
      });
    }
  }
}
