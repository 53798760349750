import { Component, Input } from '@angular/core';
import { TipStatus } from 'src/app/tips/tips.service';
import { TimeagoIntl } from 'ngx-timeago';
import { strings as englishShortStrings } from 'ngx-timeago/language-strings/en-short';
import { FeedItem } from '../feed.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { HealthPassConfigState } from 'src/app/health-pass/health-pass-config.state';

@Component({
  selector: 'dash-feed-card',
  templateUrl: './feed-card.component.html',
  styleUrls: ['./feed-card.component.scss']
})
export class FeedCardComponent {
  @SelectSnapshot(HealthPassConfigState.label) label: string;

  constructor(private intl: TimeagoIntl) { }

  @Input() item: FeedItem;
  tipStatus = TipStatus;

  lastActivity(): number {
    this.intl.strings = englishShortStrings;
    this.intl.changes.next();

    return this.item.lastActivity;
  }
}
