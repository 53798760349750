import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@dashboard/env';
import { Observable } from 'rxjs';
import { Organization } from 'src/app/core/models/organization.model';
import { AccountId } from 'src/app/core/models/type-tokens';
import { Attachment, FileModel, Location } from '@dashboard/core';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { TipsFeedState } from './feed/tips-feed.state';
import { TipsFeed } from './feed/tips-feed.actions';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { TipType } from '../organizations/organization/tip-type-config.service';

interface TipParticipant {
  id: string;
  accountId: AccountId;
  displayName: string;
}

export enum TipStatus {
  Closed = 'Closed',
  Open = 'Open',
  Archived = 'Archived',
  New = 'New',
  Deleted = 'Deleted'
}

export interface Tip {
  id: string;
  tipConfig: TipType;
  created: number;
  submitter: TipParticipant;
  organization: Organization;
  attachments?: Attachment[];
  location?: Location;
  status: TipStatus;
  description: string;
}

export interface Chat {
  id: string;
  accountId: AccountId;
  displayName: string;
  bodyText: string;
  readBy: AccountId[];
  attachments?: Attachment[];
  created: number;
  location?: Location;
}

export interface TipSummary {
  tip: Tip;
  isUnread: boolean;
  lastChat?: Chat;
}

export interface GetTipResponse {
  data: TipSummary[];
  pagination: Pagination;
}

export interface GetTipChatResponse {
  data: Chat[];
  pagination: Pagination
}

export interface Pagination {
  skip: number;
  limit: number;
  moreItems: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TipsService {
  @Select(TipsFeedState.pagination) feedPagination$: Observable<Pagination>;

  @Dispatch()
  private updateFeedPagination(moreItems: boolean) {
    return new TipsFeed.UpdatePagination(moreItems);
  }

  constructor(private http: HttpClient) { }

  private get baseUrl() {
    return `${environment.DASHBOARD_APP_API}/tips`;
  }

  getTips(nextPage: boolean): Observable<TipSummary[]> {
    if (nextPage) {
      return this.feedPagination$.pipe(
        filter((pagination: Pagination) => pagination.moreItems),
        switchMap((pagination: Pagination) => this.http.get<GetTipResponse>(`${this.baseUrl}?skip=${pagination?.skip}&limit=${pagination?.limit}`)),
        tap((tips: GetTipResponse) => this.updateFeedPagination(tips.pagination.moreItems)),
        map((tips: GetTipResponse) => <TipSummary[]>tips.data)
      );
    }

    return this.http.get<GetTipResponse>(`${this.baseUrl}`).pipe(
      tap((tips: GetTipResponse) => this.updateFeedPagination(tips.pagination.moreItems)),
      map((tips: GetTipResponse) => <TipSummary[]>tips.data)
    );
  }

  getTip(tipId: string): Observable<Tip> {
    return this.http.get<Tip>(`${this.baseUrl}/${tipId}`);
  }

  getChats(tipId: string, skip: number, limit: number): Observable<GetTipChatResponse> {
    return this.http.get<GetTipChatResponse>(`${this.baseUrl}/${tipId}/chats?skip=${skip}&limit=${limit}`);
  }

  openTip(tipId: string): Observable<Tip> {
    return this.http.post<Tip>(`${this.baseUrl}/${tipId}/open`, {});
  }

  closeTip(tipId: string): Observable<Tip> {
    return this.http.post<Tip>(`${this.baseUrl}/${tipId}/close`, {});
  }

  sendChat(tipId: string, chat: {
    bodyText: string;
    attachmentsList: FileModel[]
  }): Observable<Chat> {
    const formData = new FormData();
    const attachments: FileModel[] = chat.attachmentsList || [];
    attachments.forEach(a => {
      if (a.data) {
        formData.append('files', a.data);
      }
    });
    formData.append('data', JSON.stringify({ bodyText: chat.bodyText }));

    return this.http.post<Chat>(`${this.baseUrl}/${tipId}/chats`, formData);
  }

  newTip(tip: Tip): Observable<Tip> {
    return this.http.post<Tip>(`${this.baseUrl}`, tip);
  }

  markTipRead(tipId: string): Observable<Tip> {
    return this.http.post<Tip>(`${this.baseUrl}/${tipId}/read`, {});
  }
}
