import { NgModule } from '@angular/core';
import { LargeThumbnailComponent } from './large-thumbnail.component';
import { LiveSafeMaterialModule } from '@livesafe/material';
import { LiveSafeCommonModule } from '@livesafe/common';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  imports: [
    CommonModule,
    LiveSafeCommonModule,
    LiveSafeMaterialModule,
    MatRippleModule
  ],
  exports: [LargeThumbnailComponent],
  declarations: [
    LargeThumbnailComponent
  ],
  providers: [],
})
export class LargeThumbnailModule { }
