/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit, OnDestroy, forwardRef, Input } from '@angular/core';
import { IconState, Category } from '../icon.state';
import { Icon } from '../icon.service';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { UserRole } from '@dashboard/core';

@Component({
  selector: 'dash-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconSelectComponent),
      multi: true
    }
  ]
})
export class IconSelectComponent implements OnDestroy, OnInit, ControlValueAccessor {
  selectedCategory: Category;
  selectedCategoryIcons: Icon[];
  formControl = new FormControl('');
  subscription: Subscription;
  UserRole = UserRole;
  @Input() form: FormGroup;
  @Select(IconState.categories) categories$: Observable<Category[]>;
  @SelectSnapshot(IconState.categories) categories: Category[];
  private onChange = (_: unknown) => {};

  ngOnInit() {
    this.subscription = this.formControl.valueChanges
      .subscribe(v => this.onChange(v));
  }

  writeValue(icon: string): void {
    let category = this.categories.find(c => {
      return c.icons.find(i => i.name === icon);
    });

    category = category ? category : this.categories[1];

    this.formControl.setValue(icon);
    this.selectCategory(category!);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn): void { this.onChange = fn; }
  registerOnTouched(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectCategory(category: Category): void {
    this.selectedCategory = category;
    this.selectedCategoryIcons = category.icons;
  }
}
