import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { hideFeature } from '@dashboard/core';


@Component({
  selector: 'dash-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  @Input() username: string;
  @Input() role: string;
  @Input() canInviteToLivesafe = false;
  @Input() canPromoteToEnterpriseOwner = false;
  @Input() canHaveEnterpriseRoleRevoked = false;
  @Output() inviteToLiveSafe = new EventEmitter();
  @Output() promoteToEnterpriseOwner = new EventEmitter();
  @Output() revokeEnterpriseRole = new EventEmitter();

  constructor(private location: Location) { }

  ngOnInit(): void {
    const { usersAndGroupsCrud } = hideFeature;
    this.canHaveEnterpriseRoleRevoked = !usersAndGroupsCrud && this.canHaveEnterpriseRoleRevoked;
    this.canInviteToLivesafe = !usersAndGroupsCrud && this.canInviteToLivesafe;
    this.canPromoteToEnterpriseOwner = !usersAndGroupsCrud && this.canPromoteToEnterpriseOwner;
  }

  goBack(): void {
    this.location.back();
  }
}
