import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { HealthPassRealTimeMessage } from '../services/pubnub-health-pass.service';
import { TipsRealTimeMessage } from '../services/pubnub-tips.service';
import { HealthPass, Tips } from './real-time.actions';

export class TipsEventStateModel {
  type: string;
  tipId?: string;
}

export class HealthPassEventStateModel {
  type: string;
  healthPassId?: string;
}

export class RealTimeModel {
  tipsLog: TipsEventStateModel[];
  healthPassLog: HealthPassEventStateModel[];
}

@State<RealTimeModel>({
  name: 'realTime',
  defaults: {
    tipsLog: [],
    healthPassLog: []
  }
})
@Injectable()
export class RealTimeState {
  @Selector()
  static tipEvents(state: RealTimeModel): TipsEventStateModel[] {
    return state.tipsLog;
  }

  @Selector()
  static healthPassEvents(state: RealTimeModel): HealthPassEventStateModel[] {
    return state.healthPassLog;
  }

  @Action(Tips.StatusChanged)
  tipStatusChanged(ctx: StateContext<RealTimeModel>, action: Tips.StatusChanged): void {
    this.logTipsMessages(ctx, action.payload);
  }

  @Action(Tips.ChatSent)
  tipChatSent(ctx: StateContext<RealTimeModel>, action: Tips.ChatSent): void {
    this.logTipsMessages(ctx, action.payload);
  }

  @Action(Tips.Created)
  tipCreated(ctx: StateContext<RealTimeModel>, action: Tips.Created): void {
    this.logTipsMessages(ctx, action.payload);
  }

  @Action(HealthPass.StatusChanged)
  healthPassStatusChanged(ctx: StateContext<RealTimeModel>, action: HealthPass.StatusChanged): void {
    this.logHealthPassMessages(ctx, action.payload);
  }

  @Action(HealthPass.ChatSent)
  healthPassChatSent(ctx: StateContext<RealTimeModel>, action: HealthPass.ChatSent): void {
    this.logHealthPassMessages(ctx, action.payload);
  }

  @Action(HealthPass.Created)
  healthPassCreated(ctx: StateContext<RealTimeModel>, action: HealthPass.Created): void {
    this.logHealthPassMessages(ctx, action.payload);
  }

  private logTipsMessages(ctx: StateContext<RealTimeModel>, action: TipsRealTimeMessage): void {
    const newItem = <TipsEventStateModel>{
      type: action.message,
      tipId: action.tipId
    };

    ctx.setState(
      patch({
        tipsLog: append([newItem])
      })
    );
  }

  private logHealthPassMessages(ctx: StateContext<RealTimeModel>, action: HealthPassRealTimeMessage): void {
    const newItem = <HealthPassEventStateModel>{
      type: action.message,
      healthPassId: action.healthPassId
    };

    ctx.setState(
      patch({
        healthPassLog: append([newItem])
      })
    );
  }
}
