import { Action, State, StateContext } from '@ngxs/store';
import { FeedItem } from '@dashboard/core/models';

import {
  AppendToFeed,
  SetFeed,
  SetFeedError,
  SetFeedHasUnread,
  UpdateFeedItemReadStatus,
  UpdateFeedItems,
  UpdateFeedItemWorkflowStatus
} from './feed.actions';
import { Injectable } from '@angular/core';

export class FeedStateModel {
  readonly items: FeedItem[];
  readonly canLoadMore: boolean;
  readonly hasUnread: boolean;
  readonly lastUpdate: number;
  readonly error?: string;
}

const getLastUpdatedDate = (): number => new Date().getTime();

@State<FeedStateModel>({
  name: 'feed',
  defaults: {
    items: [],
    canLoadMore: true,
    hasUnread: false,
    lastUpdate: getLastUpdatedDate()
  }
})
@Injectable()
export class FeedState {
  @Action(SetFeed)
  setFeed(ctx: StateContext<FeedStateModel>, action: SetFeed): void {
    ctx.setState({
      items: action.payload,
      canLoadMore: true,
      hasUnread: action.hasUnread,
      lastUpdate: getLastUpdatedDate()
    });
  }

  @Action(AppendToFeed)
  appendToFeed(ctx: StateContext<FeedStateModel>, action: AppendToFeed): void {
    const state = ctx.getState();
    const feed = [
      ...state.items,
      ...action.payload.map(feedItem => ({ type: feedItem.type, item: feedItem.item }))
    ];

    ctx.setState({
      items: feed,
      canLoadMore: action.canLoadMore,
      hasUnread: action.hasUnread,
      lastUpdate: getLastUpdatedDate()
    });
  }

  @Action(UpdateFeedItems)
  updateFeedItems(ctx: StateContext<FeedStateModel>, action: UpdateFeedItems): void {
    const state = ctx.getState();
    let feed: FeedItem[] = [...state.items];

    if (action.payload.length) {
      action.payload.forEach((updatedFeedItem: FeedItem) => {
        const index = state.items.findIndex(
          (currentFeedItem: FeedItem) => currentFeedItem.item.id === updatedFeedItem.item.id
        );

        if (index === -1) {
          feed.push(updatedFeedItem);
        } else {
          feed[index].item = updatedFeedItem.item;
        }
      });

      feed = feed.sort(
        (a: FeedItem, b: FeedItem) =>
          new Date(b.item.lastActivityDate).getTime() - new Date(a.item.lastActivityDate).getTime()
      );

      ctx.patchState({
        items: feed,
        hasUnread: action.hasUnread,
        lastUpdate: getLastUpdatedDate()
      });
    }
  }

  @Action(SetFeedHasUnread)
  setHasUnread(ctx: StateContext<FeedStateModel>, action: SetFeedHasUnread): void {
    ctx.patchState({ hasUnread: action.payload, lastUpdate: getLastUpdatedDate() });
  }

  @Action(SetFeedError)
  setError(ctx: StateContext<FeedStateModel>, action: SetFeedError): void {
    ctx.setState({
      items: [],
      canLoadMore: true,
      hasUnread: false,
      lastUpdate: getLastUpdatedDate(),
      error: action.payload
    });
  }

  @Action(UpdateFeedItemReadStatus)
  updateFeedItemReadStatus(
    ctx: StateContext<FeedStateModel>,
    action: UpdateFeedItemReadStatus
  ): void {
    const state = ctx.getState();
    const feed: FeedItem[] = [...state.items];

    const index = feed.findIndex(
      (feedItem: FeedItem) => feedItem.type === action.type && feedItem.item.id === action.id
    );

    if (index > -1) {
      feed[index].item.isUnread = action.isUnread;
      ctx.patchState({ items: feed });
    }
  }

  @Action(UpdateFeedItemWorkflowStatus)
  updateFeedItemWorkflowStatus(
    ctx: StateContext<FeedStateModel>,
    action: UpdateFeedItemWorkflowStatus
  ): void {
    const state = ctx.getState();
    const feed: FeedItem[] = [...state.items];

    const index = feed.findIndex(
      (feedItem: FeedItem) => feedItem.type === action.type && feedItem.item.id === action.id
    );

    if (index > -1) {
      feed[index].item.isClosed = action.isClosed;
      ctx.patchState({ items: feed });
    }
  }
}
