import { TipType, TipTypeConfigurations } from '../../tip-type-config.service';
import { Homescreen } from '../homescreen.model';

export namespace HomescreenContainer {
  export class Init {
    static readonly type = '[Homescreen] Init Containers';
    constructor(public containers: Homescreen.Container[]) {}
  }

  export class InitTipTypes {
    static readonly type = '[Homescreen] Init Tip Types';
    constructor(public tipTypes: TipType[]) {}
  }

  export class InitTipTypeConfigs {
    static readonly type = '[Homescreen] Init Tip Type Configs';
    constructor(public tipTypeConfig: TipTypeConfigurations) {}
  }

  export class InitHealthPassFields {
    static readonly type = '[Homescreen] Init Health Pass Fields';
    constructor(public fields: Homescreen.HealthPassConfig) {}
  }

  export class Add {
    static readonly type = '[Homescreen] Add Container';
    constructor(public container: Homescreen.Container) {}
  }

  export class Edit {
    static readonly type = '[Homescreen] Edit Container';
    constructor(public container: Homescreen.Container) {}
  }

  export class Delete {
    static readonly type = '[Homescreen] Delete Container';
    constructor(public container: Homescreen.Container) {}
  }

  export class UpdateDisplayOrder {
    static readonly type = '[Homescreen] Drag and Drop';
    constructor(
      public previousIndex: number,
      public currentIndex: number
    ) {}
  }

  export class StopEditing {
    static readonly type = '[Homescreen] Stop Editing';
  }

  export class Update {
    static readonly type = '[Homescreen] Update Card';
    constructor(
      public container: Homescreen.Container,
      public newContainer: Homescreen.Container
    ) {}
  }

  export class UpdateTipTypes {
    static readonly type = '[Homescreen] Update Tip Types';
  }

  export class ResetEditingContainer {
    static readonly type = '[Homescreen] Reset Editing Container';
  }

  export class UpdateEditingContainer {
    static readonly type = '[Homescreen] Update Editing Container';
    constructor(public container: Partial<Homescreen.Container>) {}
  }

  export class UpdateHealthPassFields {
    static readonly type = '[Homescreen] Update Health Pass Fields';
    constructor(public fields: TipType) {}
  }

  export class UpdateTipTypeConfigFields {
    static readonly type = '[Homescreen] Update Tip Config Fields';
    constructor(public fields: TipType) {}
  }

  export class SelectTipType {
    static readonly type = '[Homescreen] Select Tip Type';
    constructor(public tipId: string) {}
  }
}
