import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseTransform'
})
export class CamelCaseTransformPipe implements PipeTransform {
  transform(text: string): string {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    return finalResult;
  }
}
