import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { map, expand, reduce } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import { environment } from '@dashboard/env';

export interface Icon {
  name: string;
  category: string;
  url: string;
  number: number;
}

interface S3Response {
  nextContinuationToken?: string;
  icons: Icon[];
}

/**
 * Custom Encoder to handle S3 continuation-tokens that often have special characters in them
 * https://medium.com/better-programming/how-to-fix-angular-httpclient-not-escaping-url-parameters-ddce3f9b8746
 */
class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private API_URL = environment.ICONS_URL;
  constructor(private http: HttpClient) {}

  /**
   * Returns all available icons
   * TODO: don't call S3 directly, move this logic to the API
   */
  getIcons(): Observable<Icon[]> {
    return this.getPaginatedIcons()
      .pipe(
        expand(res => res.nextContinuationToken ? this.getPaginatedIcons(res.nextContinuationToken) : EMPTY),
        map(res => res.icons),
        reduce((acc, val) => acc.concat(val))
      );
  }

  private getPaginatedIcons(nextContinuationToken?: string): Observable<S3Response> {
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/xml',
        'Accept': 'application/xml'
      }),
      responseType: 'text' as 'json' // https://github.com/angular/angular/issues/18586#issuecomment-327417876 😔
    };
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('list-type', '2');
    if (nextContinuationToken) {
      params = params.append('continuation-token', nextContinuationToken);
    }

    requestOptions['params'] = params;

    return this.http.get<string>(this.API_URL, requestOptions)
      .pipe(
        map(res => this.xmlToJson(this.API_URL, res))
      );
  }

  /**
   * 🙈🙉🙊
   * @param url icon base url
   * @param xml XML returned from S3
   */
  private xmlToJson(url: string, xml: string): S3Response {
    const parser = new DOMParser();
    const dom = parser.parseFromString(xml, 'application/xml');
    const isTruncated = this.getXmlValue(dom.documentElement, 'IsTruncated') === 'true' ? true : false;
    const response = {
      icons: Array.from(dom.documentElement.getElementsByTagName('Contents')).map((d: Element) => {
        const name = this.getXmlValue(d, 'Key').split('.')[0];
        const iconName = name.split('/')[1];
        const parts = iconName.split('-');
        const number = parseInt(parts.pop()!, 0);
        const category = parts.map(part => `${part.charAt(0).toUpperCase()}${part.slice(1)}`).join(' ');
        return {
          name: iconName,
          category,
          url: `${url}/homescreen/${iconName}`,
          number
        };
      })
    };

    if (isTruncated) {
      response['nextContinuationToken'] = this.getXmlValue(dom.documentElement, 'NextContinuationToken');
    }

    return response;
  }

  private getXmlValue(element: Element, tagName: string): string {
    return Array.from(element.getElementsByTagName(tagName))[0].innerHTML;
  }
}
