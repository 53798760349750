import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSelectComponent } from './icon-select/icon-select.component';
import { IconRegistry } from './icon-registry.service';
import { IconService } from './icon.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigHexComponent } from './hex/hex.component';
import { NgxsModule } from '@ngxs/store';
import { IconState } from './icon.state';
import { LiveSafeMaterialModule } from '@livesafe/material';
import { LiveSafeCommonModule } from '@livesafe/common';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LiveSafeMaterialModule,
    LiveSafeCommonModule,
    NgxsModule.forFeature([IconState]),
    NgxPermissionsModule.forChild()
  ],
  declarations: [
    IconSelectComponent,
    ConfigHexComponent
  ],
  exports: [
    IconSelectComponent
  ],
  providers: [
    IconRegistry,
    IconService
  ]
})
export class IconModule {}
