/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PubNubAngular } from 'pubnub-angular2';
import { TipsPubNubService } from './pubnub-tips.service';
import { HealthPassPubNubService } from './pubnub-health-pass.service';

export interface RealTimeMessage {
  message: string;
  timetoken: string;
}

export const tipsMessages = {
  tipChatSent: 'TipChatSent',
  tipCreated: 'TipCreated',
  tipStatusChanged: 'TipStatusChanged'
}

export const healthPassMessages = {
  healthPassChatSent: 'HealthPassChatSent',
  healthPassCreated: 'HealthPassCreated',
  healthPassStatusChanged: 'HealthPassStatusChanged'
}

const realTimeEvents = {
  ...tipsMessages,
  ...healthPassMessages
}

enum services {
  tips = '$tipsPubNub',
  health = '$healthPassPubNub'
}

@Injectable({
  providedIn: 'root'
})
export class PubNubService {

  // Ignoring warnings because these services are being used, see line 77
  constructor(
    // @ts-ignore
    private $tipsPubNub: TipsPubNubService,
    // @ts-ignore
    private $healthPassPubNub: HealthPassPubNubService
  ) { }

  private init() {
    const pnInstance = new PubNubAngular();
    pnInstance.init({
      publishKey: environment.PUBNUB_PUBLISH_KEY,
      subscribeKey: environment.PUBNUB_SUBSCRIBE_KEY
    });

    return pnInstance;
  }

  subscribe(channels: string[]): void {
    const instance = this.init();
    instance.subscribe({
      channels: [...channels],
      triggerEvents: true,
      withPresence: false
    });

    this.tipsMessagesListener(instance, channels);
  }

  private tipsMessagesListener(instance, channels: string[]): void {
    channels.forEach(channel => instance.getMessage(
      channel,
      (msg) => {
        const data = JSON.parse(msg.message);
        const action = this.getAction(data.event);
        const service = this.getService(channel);

        this[service][action]({
          message: data.event,
          tipId: data.tipId || '',
          healthPassId: data.healthPassId || '',
          timetoken: msg.timetoken
        });
      }
    ));
  }

  private getAction(event: string): string {
    return Object.keys(realTimeEvents).find(key => realTimeEvents[key] === event)!;
  }

  private getService(channel: string): string {
    return services[channel.split('-')[0]];
  }
}
