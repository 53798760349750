import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnterpriseRolesResponse, PermissionService, Role, SystemGeneratedRole } from 'src/app/core/services/permission.service';
import { EnterpriseRolesActions } from './enterprise-roles.actions';

export class RoleStateModel {
  roles: Role[];
}

@State<RoleStateModel>({
  name: 'enterpriseroles',
  defaults: {
    roles: []
  }
})
@Injectable()
export class EnterpriseRolesState {
  constructor(
    private $permission: PermissionService
  ) {}

  @Selector()
  static enterpriseOwnerRole(state: RoleStateModel): Role | undefined { return state.roles.find(r => r.key === SystemGeneratedRole.EnterpriseOwner); }

  @Action(EnterpriseRolesActions.Fetch)
  fetchRoles(ctx: StateContext<RoleStateModel>, action: EnterpriseRolesActions.Fetch): Observable<EnterpriseRolesResponse> {
    return this.$permission.getEnterpriseRoles(action.enterpriseId)
      .pipe(
        tap((res: EnterpriseRolesResponse) => ctx.patchState({ roles: res.data }))
      );
  }
}
