import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Homescreen } from '../organizations/organization/homescreen/homescreen.model';
import { HomescreenService } from '../organizations/organization/homescreen/homescreen.service';
import { fields } from '../organizations/organization/homescreen/state/homescreen.state';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EnterpriseService, OrganizationId } from '@dashboard/core';
import { HealthPassConfig } from './health-pass-config.actions';

export class HealthPassConfigStateModel {
  orgId: OrganizationId;
  config: Homescreen.HealthPassConfig;
}

@State<HealthPassConfigStateModel>({
  name: 'healthPassConfig',
  defaults: {
    orgId: '' as OrganizationId,
    config: fields
  }
})
@Injectable()
export class HealthPassConfigState {
  constructor(
    private $homescreen: HomescreenService,
    private $enterprise: EnterpriseService
  ) { }

  @Selector()
  static label(state: HealthPassConfigStateModel): string {
    return state.config.label;
  }

  @Selector()
  static verifiedLabel(state: HealthPassConfigStateModel): string {
    return state.config.verifiedLabel;
  }

  @Selector()
  static orgId(state: HealthPassConfigStateModel): OrganizationId {
    return state.orgId as OrganizationId;
  }

  @Action(HealthPassConfig.Fetch)
  fetchHealthPassConfig(ctx: StateContext<HealthPassConfigStateModel>): Observable<Homescreen.HealthPassConfig> {
    return this.$enterprise.getEnterpriseOrganizations().pipe(
      switchMap((orgs) => {
        const orgId = orgs.map(org => org.id).sort()[0];
        ctx.patchState(({ orgId }));
        return this.$homescreen.getHealthPassOrgConfig(orgId);
      }),
      tap((res) => ctx.patchState({ config: res }))
    );
  }
}
