import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MaskDirective, NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LiveSafeMaterialModule } from '@livesafe/material';
import { LiveSafeCommonModule } from '@livesafe/common';
import { environment } from 'src/environments/environment';
import { SafePipeModule } from 'safe-pipe';
import { IconModule } from './components/icon/icon.module';
import { TimeagoModule, TimeagoFormatter, TimeagoIntl } from 'ngx-timeago';
import { TimeagoCustomFormatter } from './services/timeago-custom-formatter';

import {
  EditableInfoCardComponent,
  ImageComponent,
  LoaderComponent,
  NoSoundDialogComponent,
  OrgTreeNodeComponent,
  SearchFieldComponent,
  VideoPlayerComponent,
  UploadCarouselComponent,
  NewMessagesDividerComponent,
  BottomSheetComponent,
  UserListComponent,
  UserProfileComponent,
  UserHeaderComponent,
  FeedCardComponent,
  FeedCardLoadingComponent,
  FeedComponent,
  EmptyFeedDetailComponent,
  FeedDetailHeaderComponent,
  SystemMessageChatComponent,
  ConnectMediaCarouselComponent,
  LoadingComponent
} from './components';
import {
  FocusOnInitDirective,
  InputAutofillDirective,
  NamedOutletDirective,
  PasswordToggleDirective,
  CamelCaseTransformPipe
} from './directives';
import { LargeThumbnailModule } from './components/large-thumbnail/large-thumbnail.module';

@NgModule({
  imports: [
    CommonModule,
    LiveSafeMaterialModule,
    SafePipeModule,
    LiveSafeCommonModule.forRoot({
      gmapsApiKey: environment.GMAPS_API_KEY
    }),
    LargeThumbnailModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    TimeagoModule.forChild({
      formatter: {provide: TimeagoFormatter, useClass: TimeagoCustomFormatter},
      intl: {provide: TimeagoIntl},
    }),
    NgxMaskModule.forRoot()
  ],
  declarations: [
    NamedOutletDirective,
    SearchFieldComponent,
    VideoPlayerComponent,
    NoSoundDialogComponent,
    LoaderComponent,
    EditableInfoCardComponent,
    OrgTreeNodeComponent,
    ImageComponent,
    FocusOnInitDirective,
    PasswordToggleDirective,
    CamelCaseTransformPipe,
    UploadCarouselComponent,
    NewMessagesDividerComponent,
    InputAutofillDirective,
    BottomSheetComponent,
    UserListComponent,
    UserProfileComponent,
    UserHeaderComponent,
    FeedCardComponent,
    FeedCardLoadingComponent,
    FeedComponent,
    EmptyFeedDetailComponent,
    FeedDetailHeaderComponent,
    SystemMessageChatComponent,
    ConnectMediaCarouselComponent,
    LoadingComponent
  ],
  exports: [
    SafePipeModule,
    LiveSafeMaterialModule,
    LiveSafeCommonModule,
    LargeThumbnailModule,
    NamedOutletDirective,
    RouterModule,
    SearchFieldComponent,
    LoaderComponent,
    EditableInfoCardComponent,
    OrgTreeNodeComponent,
    ImageComponent,
    FocusOnInitDirective,
    PasswordToggleDirective,
    CamelCaseTransformPipe,
    MaskDirective,
    UploadCarouselComponent,
    VideoPlayerComponent,
    NewMessagesDividerComponent,
    InputAutofillDirective,
    BottomSheetComponent,
    UserListComponent,
    UserProfileComponent,
    UserHeaderComponent,
    FeedComponent,
    EmptyFeedDetailComponent,
    FeedDetailHeaderComponent,
    SystemMessageChatComponent,
    IconModule,
    CommonModule,
    ConnectMediaCarouselComponent,
    NgxPermissionsModule,
    LoadingComponent
  ]
})
export class SharedModule {}
