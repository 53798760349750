import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistry {
  constructor(private $matIconRegistry: MatIconRegistry, private $domSanitizer: DomSanitizer) {}

  initSvgs(svgFilenames: string[], pathToSvgFiles: string): void {
    if (pathToSvgFiles.endsWith('/')) {
      pathToSvgFiles.substring(0, pathToSvgFiles.length - 1)
    }

    svgFilenames.forEach((svgFilename: string) => {
      this.$matIconRegistry.addSvgIconInNamespace(
        'homescreen',
        svgFilename,
        this.$domSanitizer.bypassSecurityTrustResourceUrl(`${pathToSvgFiles}/homescreen/${svgFilename}`)
      );
    });
  }
}
