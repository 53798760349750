import { Injectable } from '@angular/core';
import { HealthPass } from '../state/real-time.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RealTimeMessage } from './pubnub.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { SessionState } from '../state';

export class HealthPassRealTimeMessage implements RealTimeMessage {
  message: string;
  timetoken: string;
  healthPassId: string;
}

@Injectable({
  providedIn: 'root'
})
export class HealthPassPubNubService {
  @SelectSnapshot(SessionState.currentEnterpriseId) currentEnterpriseId: string;

  @Dispatch()
  healthPassChatSent(healthPassMessage: HealthPassRealTimeMessage): HealthPass.ChatSent {
    return new HealthPass.ChatSent(healthPassMessage);
  }

  @Dispatch()
  healthPassCreated(healthPassMessage: HealthPassRealTimeMessage): HealthPass.Created {
    return new HealthPass.Created(healthPassMessage);
  }

  @Dispatch()
  healthPassStatusChanged(healthPassMessage: HealthPassRealTimeMessage): HealthPass.StatusChanged {
    return new HealthPass.StatusChanged(healthPassMessage);
  }

  get healthPassChannel(): string {
    return `health-pass-${this.currentEnterpriseId}`;
  }
}
