/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, Validators, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { patternValidator, HEX_COLOR_REGEXP } from '@dashboard/core/util';
import { SessionState } from '@dashboard/core';
import { Select } from '@ngxs/store';

@Component({
  selector: 'dash-config-hex',
  templateUrl: './hex.component.html',
  styleUrls: ['./hex.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfigHexComponent),
      multi: true
    }
  ]
})
export class ConfigHexComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Select(SessionState.hasHomescreenConfigPermission) hasHomescreenConfigPermission$: Observable<boolean>;
  private subscription: Subscription;
  @Input() form: FormGroup;
  formControl = new FormControl('', [Validators.required, patternValidator(new RegExp(HEX_COLOR_REGEXP), { invalidHex: true}), Validators.minLength(7), Validators.maxLength(7)]);
  private onChange = (_: unknown) => {};

  ngOnInit() {
    this.subscription = this.formControl.valueChanges
      .subscribe(hex => {
        if (hex[0] !== '#') {
          hex = `#${hex}`;
        }
        hex = hex.substr(0, 7);
        this.onChange(hex);
        this.formControl.setValue(hex, { emitEvent: false });
      });
  }

  writeValue(hex: string): void {
    this.formControl.setValue(hex);
  }

  registerOnChange(fn): void { this.onChange = fn; }
  registerOnTouched(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
