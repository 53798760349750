import { patch } from '@dashboard/core/util';

import { Attachment } from './media.model';
import { AccountId, Message, MessageId } from './type-tokens';

export class ChatMessage {
  id: MessageId;
  submitter: Submitter;
  source: MessageSource;
  createdAt: number;
  message: Message;
  attachments: Attachment[];

  constructor(seed: Partial<ChatMessage> = {}) {
    patch(this, seed);
  }
}

export class Submitter {
  readonly id: AccountId;
  readonly name?: string;

  constructor(seed: Partial<Submitter> = {}) {
    patch(this, seed);
  }
}

export enum MessageSource {
  Dashboard = 'dashboard',
  Submitter = 'submitter'
}
