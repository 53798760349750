import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

enum FormatsEnum {
  lc = 'lc',
  up = 'up',
  lctrim = 'lctrim',
  uptrim = 'uptrim'
}

interface Formatters {
  [key: string]: (string: string) => string
}

@Directive({
  selector: '[dashInputAutofill]'
})
export class InputAutofillDirective implements OnInit, OnDestroy {
  @Input() dashInputAutofill: string;
  @Input() format: string = FormatsEnum.lc;

  private control: AbstractControl | null;
  private fillerControl: AbstractControl | null | undefined;
  private isControlOnFocus = false;

  private $unsubscribe: Subject<void> = new Subject<void>();

  private readonly formatters: Formatters = {
    [FormatsEnum.lc]: (string: string) => {
      return string.toLowerCase();
    },
    [FormatsEnum.up]: (string: string) => {
      return string.toUpperCase();
    },
    [FormatsEnum.lctrim]: (string: string) => {
      return this.noWhitespaces(string.toLowerCase());
    },
    [FormatsEnum.uptrim]: (string: string) => {
      return this.noWhitespaces(string.toUpperCase());
    }
  }

  constructor(private ngControl: NgControl) {}

  @HostListener('blur')onBlur(): void {
    this.isControlOnFocus = false;
  }

  @HostListener('focus')onFocus(): void {
    this.isControlOnFocus = true;
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  ngOnInit() {
    this.control = this.ngControl.control;
    this.fillerControl = this.control?.parent?.get(this.dashInputAutofill);

    if (!(this.control && this.fillerControl)) {
      return;
    }

    let shouldAutofill = false;

    this.fillerControl?.valueChanges.pipe(takeUntil(this.$unsubscribe))
      .subscribe((value: string) => {
        if (this.control?.value && shouldAutofill) {
          return;
        }

        const controlValue = (this.format in FormatsEnum) ? this.formatters[this.format](value) : value;
        this.control?.setValue(controlValue);
    });

    this.control?.valueChanges.pipe(takeUntil(this.$unsubscribe))
      .subscribe((value: string) => {
      shouldAutofill = (!value && this.isControlOnFocus || value && !this.isControlOnFocus) ? false : true;
    });
  }

  private noWhitespaces(string: string): string {
    return string.replace(/\s/g, '');
  }
}
