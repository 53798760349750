import { HealthPassRealTimeMessage } from '../services/pubnub-health-pass.service';
import { TipsRealTimeMessage } from '../services/pubnub-tips.service';

export namespace Tips {
  export class StatusChanged {
    static readonly type = '[Real Time] Tip status changed';
    constructor(public payload: TipsRealTimeMessage) {}
  }
  export class ChatSent {
    static readonly type = '[Real Time] Tip chat sent';
    constructor(public payload: TipsRealTimeMessage) {}
  }
  export class Created {
    static readonly type = '[Real Time] Tip created';
    constructor(public payload: TipsRealTimeMessage) {}
  }
}

export namespace HealthPass {
  export class StatusChanged {
    static readonly type = '[Real Time] Health Pass status changed';
    constructor(public payload: HealthPassRealTimeMessage) {}
  }
  export class ChatSent {
    static readonly type = '[Real Time] Health Pass chat sent';
    constructor(public payload: HealthPassRealTimeMessage) {}
  }
  export class Created {
    static readonly type = '[Real Time] Health Pass created';
    constructor(public payload: HealthPassRealTimeMessage) {}
  }
}
