import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from '@aws-amplify/auth';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { SessionState } from '../state/session.state';

@Injectable({
  providedIn: 'root'
})
export class EnterprisesGuard implements CanActivateChild, CanActivate {
  @SelectSnapshot(SessionState.currentEnterpriseId) currentEnterpriseId: string;

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const pathname = window.location.pathname;

    return Auth.currentAuthenticatedUser()
      .then(_1 => {
        console.log(`[EnterprisesGuard.canActivate] - user is authenticated :: ${pathname} :: ${this.currentEnterpriseId} :: ${state.url}`);
        if ((['/e', '/', '/e/'].includes(pathname)) && state.url === '/e') {
          return this.router.createUrlTree(['/e', this.currentEnterpriseId]);
        } else if (state.url === '/e' ) {
          return this.router.createUrlTree(['/e', this.currentEnterpriseId]);
        } else if (route.queryParams.enterpriseId && this.currentEnterpriseId !== route.queryParams.enterpriseId) {
          return this.router.createUrlTree(['/signin'], {
            queryParams: { enterpriseId: route.queryParams.enterpriseId, appState: `/e/${route.queryParams.enterpriseId}`}
          });
        } else {
          return true;
        }
      })
      .catch(_2 => {
        const enterpriseId = route.firstChild?.params.enterpriseId || route.queryParams.enterpriseId || this.currentEnterpriseId;
        const appState = pathname !== '/find-my-enterprise' ? pathname : `/e/${enterpriseId}`;
        console.log(`[EnterprisesGuard.canActivate] - user is not authenticated :: ${enterpriseId} :: ${appState}`);
        return this.router.createUrlTree(['/signin'], {
          queryParams: { enterpriseId, appState }
        });
      });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return Auth.currentAuthenticatedUser()
      .then(_ => {
        console.log('[EnterprisesGuard.canActivateChild] - user is authenticated');
        return true;
      })
      .catch(_ => {
        console.log('[EnterprisesGuard.canActivateChild] - user is not authenticated');
        return this.router.createUrlTree(['/signin'], {
          queryParams: {
            enterpriseId: route.params.enterpriseId,
            appState: state.url
          }
        });
      });
  }

}
