import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, iif, patch, updateItem } from '@ngxs/store/operators';
import { FeedItem } from 'src/app/shared/components/feed/feed.model';
import { TipSummary, Pagination } from '../tips.service';
import { TipsFeed } from './tips-feed.actions';

export class TipsFeedStateModel {
  items: FeedItem[];
  lastUpdate: number;
  pagination: Pagination;
  error: boolean;
}

const getLastUpdatedDate = (): number => new Date().getTime();
const getFeedItemFromTipSummary = (tipSummary: TipSummary): FeedItem =>
  ({
    id: tipSummary.tip.id,
    isRead: !tipSummary.isUnread,
    status: tipSummary.tip.status,
    submitter: tipSummary.tip.submitter.displayName,
    organization: tipSummary.tip.organization.name,
    type: tipSummary.tip.tipConfig?.label || '',
    lastMessage: tipSummary.lastChat ? tipSummary.lastChat.bodyText || 'Media attached' : tipSummary.tip.description,
    lastActivity: tipSummary.lastChat?.created || tipSummary.tip.created
  });

@State<TipsFeedStateModel>({
  name: 'tipFeed',
  defaults: {
    items: [],
    lastUpdate: getLastUpdatedDate(),
    error: false,
    pagination: {
      skip: 0,
      limit: 50,
      moreItems: false
    }
  }
})
@Injectable()
export class TipsFeedState {
  @Selector()
  static tips(state: TipsFeedStateModel): FeedItem[] {
    return state.items;
  }

  @Selector()
  static unreadTips(state: TipsFeedStateModel): FeedItem[] {
    return state.items.filter(tip => !tip.isRead);
  }

  @Selector()
  static pagination(state: TipsFeedStateModel): Pagination {
    return state.pagination;
  }

  @Selector()
  static error(state: TipsFeedStateModel): boolean {
    return state.error;
  }

  @Action(TipsFeed.SetFeed)
  setFeed(ctx: StateContext<TipsFeedStateModel>, action: TipsFeed.SetFeed): void {
    ctx.patchState({
      items: action.payload.map((tipSummary: TipSummary) => getFeedItemFromTipSummary(tipSummary)),
      lastUpdate: getLastUpdatedDate()
    });
  }

  @Action(TipsFeed.UpdatePagination)
  updatePagination(ctx: StateContext<TipsFeedStateModel>, action: TipsFeed.UpdatePagination): void {
    const state = ctx.getState();
    ctx.patchState({
      pagination: {
        skip: state.pagination.skip + state.pagination.limit,
        limit: 50,
        moreItems: action.moreItems
      }
    });
  }

  @Action(TipsFeed.AppendToFeed)
  appendToFeed(ctx: StateContext<TipsFeedStateModel>, action: TipsFeed.AppendToFeed): void {
    const state = ctx.getState();
    const feed = [
      ...state.items,
      ...action.payload.map(tipSummary => getFeedItemFromTipSummary(tipSummary))
    ];

    ctx.patchState({
      items: feed,
      lastUpdate: getLastUpdatedDate()
    });
  }

  @Action(TipsFeed.UpdateFeedItems)
  updateFeedItems(ctx: StateContext<TipsFeedStateModel>, action: TipsFeed.UpdateFeedItems): void {
    const state = ctx.getState();

    if (action.payload.length) {
      action.payload.forEach((updatedFeedItem: TipSummary) => {
        const index = state.items.findIndex(
          (currentFeedItem: FeedItem) => currentFeedItem.id === updatedFeedItem.tip.id
        );
        const updatedStateModel: FeedItem = getFeedItemFromTipSummary(updatedFeedItem);
        ctx.setState(
          patch({
            items: iif(
              index === -1,
              append([updatedStateModel]),
              updateItem(index, updatedStateModel)
            )
          })
        );
      });
    }
  }

  @Action(TipsFeed.MarkTipRead)
  markTipRead(ctx: StateContext<TipsFeedStateModel>, action: TipsFeed.MarkTipRead): void {
    const state = ctx.getState();
    const index = state.items.findIndex(item => item.id === action.tipId);

    ctx.setState(
      patch({
        items: updateItem<FeedItem>(index, { ...state.items[index], isRead: true })
      })
    );
  }

  @Action(TipsFeed.SetError)
  setError(ctx: StateContext<TipsFeedStateModel>, action: TipsFeed.SetError): void {
    ctx.patchState({ error: action.error });
  }


  @Action(TipsFeed.UpdateTipStatus)
  updateTipStatus(ctx: StateContext<TipsFeedStateModel>, action: TipsFeed.UpdateTipStatus): void {
    const state = ctx.getState();
    const index = state.items.findIndex(item => item.id === action.tipId);
    ctx.setState(
      patch({
        items: updateItem<FeedItem>(index, { ...state.items[index], status: action.status})
      })
    );
  }
}
