import { environment, RuntimeEnv } from '@dashboard/env';

const isDevEnv = environment.RUNTIME_ENV === RuntimeEnv.Dev || environment.RUNTIME_ENV === RuntimeEnv.LocalDev;
const isStgProdEnv = environment.RUNTIME_ENV === RuntimeEnv.Stg || environment.RUNTIME_ENV === RuntimeEnv.Production;

export const hideFeature: Record<string, boolean> = {
  // hidden in all environments
  usersAndGroupsCrud: isDevEnv || isStgProdEnv,
  orgManagementCrud: isDevEnv || isStgProdEnv,
  usersWidget: isDevEnv || isStgProdEnv,
  tips: isDevEnv || isStgProdEnv,
  userMenu: isDevEnv || isStgProdEnv,
  configureTipTypesWidget: isDevEnv || isStgProdEnv,
  orgEditButton: isDevEnv || isStgProdEnv,
  resourceCarousel: isDevEnv || isStgProdEnv,
  tipTypesSubmittalConfig: isDevEnv || isStgProdEnv,

  // hidden in higher environments
  geofencesTab: isStgProdEnv
}