import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Session, SessionState, UserRole } from '../state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  @SelectSnapshot(SessionState.currentEnterpriseId) currentEnterpriseId: string;

  private readonly urlToPermission = {
    verify: UserRole.VerifyManagement,
    connect: UserRole.ConnectManagement,
    users: UserRole.UserManagement,
    alerts: UserRole.AlertManagement,
    settings: UserRole.AdminWebAppAccess,
    homescreen: UserRole.AdminWebAppAccess,
    home: UserRole.AdminWebAppAccess,
    groups: UserRole.UserManagement
  }

  constructor(private router: Router, private $store: Store) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.$store.dispatch(new Session.GetPermissions()).pipe(
      map(res => {
        const permissions = res.ngxsSession.accountPermissions;
        return (route.routeConfig?.path && permissions?.includes(this.urlToPermission[route.routeConfig?.path]))
          || this.router.createUrlTree(['/e', this.currentEnterpriseId, 'unauthorized']);
      })
    );
  }
}
