import { Injectable } from '@angular/core';
import { environment } from '@dashboard/env';
import { Observable } from 'rxjs';
import { OrganizationId } from '@dashboard/core';
import { HttpClient } from '@angular/common/http';
import { Homescreen } from './homescreen.model';
import { TipType } from '../tip-type-config.service';
import { map } from 'rxjs/operators';

const defaultTipTypes: TipType[] = [{
  id: '1006',
  label: 'Suspicious Activity',
  iconUrl: 'https://static.livesafemobile.com/images/tiptypes/default/suspicious_activity.svg',
  hintText: '',
  suppressAnonymous: false,
  suppressLocation: false,
  tempId: '',
  trackLocation: false,
  submitConfirmationText: '',
  chatButtonText: '',
  systemMessageText: '',
  tipTypeId: ''
}, {
  id: '19',
  label: 'Emergency Message',
  iconUrl: 'https://static.livesafemobile.com/images/tiptypes/default/message.svg',
  hintText: '',
  suppressAnonymous: false,
  suppressLocation: false,
  tempId: '',
  trackLocation: false,
  submitConfirmationText: '',
  chatButtonText: '',
  systemMessageText: '',
  tipTypeId: ''
}, {
  id: '13',
  label: '911 Call',
  iconUrl: 'https://static.livesafemobile.com/images/tiptypes/default/911call.svg',
  hintText: '',
  suppressAnonymous: false,
  suppressLocation: false,
  tempId: '',
  trackLocation: false,
  submitConfirmationText: '',
  chatButtonText: '',
  systemMessageText: '',
  tipTypeId: ''
}];

@Injectable({
  providedIn: 'root'
})
export class HomescreenService {
  private url = `${environment.DASHBOARD_APP_API}/organizations`;
  constructor(private http: HttpClient) {}

  getHomescreen(orgId: OrganizationId): Observable<Homescreen.Container[]> {
    return this.http.get<Homescreen.Container[]>(`${this.url}/${orgId}/config/homescreen`);
  }

  putHomescreen(orgId: OrganizationId, containers: Homescreen.Container[]): Observable<Homescreen.Container[]> {
    const payload = this.removeStateName(containers);
    return this.http.put<Homescreen.Container[]>(`${this.url}/${orgId}/config/homescreen`, payload);
  }

  getHealthPassOrgConfig(orgId: OrganizationId): Observable<Homescreen.HealthPassConfig> {
    return this.http.get<Homescreen.HealthPassConfig>(`${environment.DASHBOARD_APP_API}/healthpass/organizations/${orgId}`);
  }

  putHealthPassOrgConfig(orgId: OrganizationId, healthPassConfig: Homescreen.HealthPassConfig): Observable<Homescreen.HealthPassConfig> {
    return this.http.put<Homescreen.HealthPassConfig>(`${environment.DASHBOARD_APP_API}/healthpass/organizations/${orgId}`, healthPassConfig);
  }

  getTipTypes(orgId: OrganizationId): Observable<TipType[]> {
    return this.http.get<TipType[]>(`${this.url}/${orgId}/config/homescreen/tips/types/LS7`).pipe(
      map(tipTypes => defaultTipTypes.concat(tipTypes))
    );
  }

  private removeStateName(containers: Homescreen.Container[]): Homescreen.Container[] {
    return containers.map(container => {
      if (container.actionType !== Homescreen.ActionType.HealthPass) {
        return container;
      }
      container.styles?.map(state => ({
        style: {
          ...state.style
        },
        cardStateId: state.cardStateId,
        styleType: state.style
      }));

      return container;
    });
  }
}
