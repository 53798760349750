import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EnterprisesGuard } from './core/guards/enterprises.guard';

const routes: Routes = [
  {
    path: 'testing',
    loadChildren: () =>
      import('./public/playground/playground.module').then(m => m.PlaygroundModule)
  },
  {
    path: 'youre-invited',
    loadChildren: () =>
      import('./public/youre-invited/youre-invited.module').then(m => m.YoureInvitedModule)
  },
  {
    path: 'jotform',
    loadChildren: () => import('./public/jotform/jotform.module').then(m => m.JotformModule)
  },
  {
    path: 'e',
    loadChildren: () => import('./+enterprises/enterprises.module').then(m => m.EnterprisesModule),
    canActivateChild: [EnterprisesGuard],
    canActivate: [EnterprisesGuard]
  },
  {
    path: 'find-my-enterprise',
    loadChildren: () => import('./public/+find-my-enterprise/find-my-enterprise.module').then(m => m.FindMyEnterpriseModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./public/+signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: 'get-started',
    loadChildren: () => import('./public/onboarding/+get-started/get-started.module').then(m => m.GetStartedModule)
  },
  {
    path: 'verify/:customerId',
    loadChildren: () => import('./public/onboarding/+verify/verify.module').then(m => m.VerifyModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule)
  },
  {
    path: 'create-organization',
    loadChildren: () =>
      import('./create-organization/create-organization.module').then(
        m => m.CreateOrganizationModule
      )
  },
  {
    path: 'deeplink',
    loadChildren: () =>
      import('./public/deeplink/deeplink.module').then(
        m => m.DeeplinkModule
      )
  },
  {
    path: 'alerts/:id/survey',
    loadChildren: () =>
      import('./survey/survey.module').then(
        m => m.SurveyModule
      )
  },
  {
    path: 'alerts/:id/checkin',
    loadChildren: () =>
      import('./public/+checkin-response/checkin-response.module').then(m => m.CheckinResponseModule)
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('./health-pass-verification/health-pass-verification.module').then(
        m => m.HealthPassVerificationModule
      )
  },
  {
    path: '',
    redirectTo: 'e',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    /**
     * Important not to remove `preloadingStrategy: PreloadAllModules`.
     * This is required as we have rolling deployments of the Nginx containers
     * that this application runs in.
     *
     * All assets must be sent to the client as soon as the application loads
     * since the container may be replaced while the app is open.
     *
     * With preloading, we can still benefit from lazy loading. See:
     * https://vsavkin.com/angular-router-preloading-modules-ba3c75e424cb for a
     * more in-depth explanation.
     */
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
