import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, from, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { first, tap, map, switchMap, catchError, startWith } from 'rxjs/operators';
import { Amplify, Hub, HubCapsule } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { AuthService, WindowService } from '@dashboard/core/services';
import { Session } from '../state/session.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { EnterpriseSession, SessionAccount } from '../state/session.state';

export interface AmplifyConfig {
  Auth: unknown;
}

/**
 * PR submitted to aws-amplify-angular here with tests https://github.com/aws-amplify/amplify-js/pull/7387
 * If merged, we can get `fromHub` from the lib instead of coding this here
 * @param channel
 * @param eventName
 */
function fromHub(
  channel: string,
  eventName: string
): Observable<HubCapsule> {
  return new Observable<HubCapsule>((subscriber) => {
    function listener(data: HubCapsule) {
      switch (data.payload.event) {
        case eventName:
          subscriber.next(data);
          break;
      }
    }
    Hub.listen(channel, listener);

    return () => Hub.remove(channel, listener);
  });
}

@Injectable({
  providedIn: 'root'
})
export class SigninGuard implements CanActivate {
  @Dispatch()
  addEnterpriseSession = (enterpriseSession: EnterpriseSession): Session.AddEnterpriseSession => new Session.AddEnterpriseSession(enterpriseSession);

  @Dispatch()
  setAccount = (sessionAccount: SessionAccount): Session.SetCurrentAccount => new Session.SetCurrentAccount(sessionAccount);

  constructor(
    private $auth: AuthService,
    private router: Router,
    public $window: WindowService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    snapshot: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { enterpriseId, appState } = route.queryParams;
    console.log(`[SigninGuard.canActivate] - ${enterpriseId} :: ${snapshot.url}`);

    // We don't know which enterprise the user is trying to log into so help them find it
    if (!enterpriseId) {
      if (snapshot.url === '/find-my-enterprise') {
        return true;
      } else {
        this.$window.nativeWindow.location.href = route.data['dashboardLogin'];
        return true;
      }
    }

    return combineLatest([
      this.$auth.getAuthConfigByEnterprise(enterpriseId),
      fromHub('auth', 'customOAuthState').pipe(startWith({
        payload: {
          data: appState
        }
      }))
    ])
    .pipe(
      tap(([config, _2]) => {
        const enterpriseSession = config.enterprise;
        enterpriseSession.config = {
          ...config.Amplify,
          Auth: {
            ...config.Amplify.Auth,
            oauth: {
              ...config.Amplify.Auth.oauth,
              redirectSignIn: `${this.$window.nativeWindow.location.origin}/e/${enterpriseId}`,
              redirectSignOut: `${this.$window.nativeWindow.location.origin}/e/${enterpriseId}`,
              responseType: 'code'
            }
          }
        };

        this.addEnterpriseSession(config.enterprise);
        Amplify.configure(enterpriseSession.config);
      }),
      switchMap(([_3, customOAuthState]) => {
        return forkJoin({
          currentSession: from(Auth.currentSession()),
          customOAuthState: of(customOAuthState)
        })
        .pipe(
          first(),
          tap(session => {
            const payload = session.currentSession.getIdToken().payload;
            this.setAccount({
              id: payload['livesafe:account_id'],
              givenName: payload['given_name'] || '',
              phoneNumber: payload['phone_number'] || '',
              email: payload['email'],
              familyName: payload['family_name'] || ''
            });
          }),
          catchError(error => {
            Amplify.Auth.federatedSignIn({
              customState: appState
            });
            return throwError(error);
          })
        )
      }),
      map(({ customOAuthState }) => {
        console.log(`[SigninGuard.canActivate.map] - redirecting to ${customOAuthState.payload.data}`);
        return this.router.createUrlTree([customOAuthState.payload.data]);
      })
    );
  }
}
