import { EnterpriseSession, SessionAccount } from './session.state';

export namespace Session {
  export class SetCurrentAccount {
    static readonly type = '[Session] Set current account';
    constructor(public account: SessionAccount) {}
  }

  export class AddEnterpriseSession {
    static readonly type = '[Session] Set current enterprise';
    constructor(public enterpriseSession: EnterpriseSession) {}
  }

  export class ActivateEnterpriseSession {
    static readonly type = '[Session] Activate enterprise';
    constructor(public enterpriseSession: EnterpriseSession) {}
  }

  export class RemoveEnterpriseSession {
    static readonly type = '[Session] Remove enterprise';
    constructor(public enterpriseId: string) {}
  }

  export class RemoveAllEnterpriseSessions {
    static readonly type = '[Session] Remove all enterprise sessions';
  }

  export class FetchPermissions {
    static readonly type = '[User Detail] Fetch permissions for user';
  }

  export class GetPermissions {
    static readonly type = '[User Detail] Get permissions for user';
  }
}
