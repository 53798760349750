import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SegmentModule } from 'ngx-segment-analytics';
import { CoreModule } from '@dashboard/core';
import { SharedModule } from '@dashboard/shared';
import { environment } from '@dashboard/env';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { createErrorHandler } from '@sentry/angular';
import { PubNubAngular } from 'pubnub-angular2';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

const isProd = environment.PROD_MODE;

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SegmentModule.forRoot({ apiKey: environment.SEGMENT, debug: isProd, loadOnInitialization: true }),
    MatPasswordStrengthModule
  ],
  providers: [
    MatDatepickerModule,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    PubNubAngular
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
