import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SessionState } from '../state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  @SelectSnapshot(SessionState.currentEnterpriseId) currentEnterpriseId: string;

  constructor(private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('/api/v1/authentication/enterprises') ||
        request.url.startsWith('/api/v1/authentication/enterprise-domains') ||
        request.url.startsWith('/api/v1/find-my-enterprise') ||
        request.url.startsWith('/api/v1/onboarding/customers') ||
        request.url.startsWith('/api/v1/clients/subdomains') ||
        request.url.startsWith('https://static.livesafemobile.net') ||
        request.url.startsWith('/test') ||
        // eslint-disable-next-line no-useless-escape
        request.url.match(/\/api\/v1\/broadcasts\/[a-zA-Z0-9]*\/survey\/[a-zA-Z0-9\/]*/g) ||
        (request.url.match(/\/api\/v1\/broadcasts\/[a-zA-Z0-9]*\/checkin\/[a-zA-Z0-9\/]*/g) && !request.url.endsWith('/checkin/bounds'))) {
      return next.handle(request);
    }

    return from(Auth.currentAuthenticatedUser())
      .pipe(
        switchMap(session => {
          return next.handle(request.clone({
            headers: request.headers.set('Authorization', `Bearer ${session.signInUserSession.accessToken.jwtToken}`)
          }));
        }),
        catchError(error => {
          if (error.status === 401) {
            // Force logout for now. To be better handled in https://livesafe.atlassian.net/browse/NEX-7886
            Auth.signOut();
          }

          if (error.status === 403) {
            this.router.navigate(['e', this.currentEnterpriseId, 'unauthorized']);
          }
          
          return throwError(error);
        })
      );
  }
}
