import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentType, EnterpriseContactEmail, EnterpriseContactPhone } from '@dashboard/core';
import { hideFeature } from '@dashboard/core';

@Component({
  selector: 'dash-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  ComponentType = ComponentType;

  @Input() firstNames: string[];
  @Input() lastNames: string[];
  @Input() emails: EnterpriseContactEmail[];
  @Input() phones: EnterpriseContactPhone[];
  @Input() editProfileInfo = true;
  @Output() clickProfileInfo: EventEmitter<ComponentType> = new EventEmitter<ComponentType>();

  ngOnInit(): void {
    this.editProfileInfo = !hideFeature.usersAndGroupsCrud && this.editProfileInfo;
  }

  clickSection(componentType: ComponentType): void {
    if (this.editProfileInfo) {
      this.clickProfileInfo.emit(componentType);
    }
  }
}
