import { HttpParams } from '@angular/common/http';
import { DashSort } from '../models';

export function addPaginationParams(params: HttpParams, skip?: number, limit?: number, sort?: DashSort): HttpParams {
  if (skip) {
    params = params.set('skip', `${skip}`);
  }

  if (limit) {
    params = params.set('limit', `${limit}`);
  }

  if (sort) {
    params = params.set('sort', `${sort}`);
  }

  return params;
}
