
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FeedItem } from './feed.model';

@Component({
  selector: 'dash-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnDestroy, AfterViewInit {
  @ViewChildren('item') item: QueryList<unknown>;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  @Input() feed$: Observable<FeedItem[]>;
  @Input() error$: Observable<boolean>;
  @Input() loading: boolean;
  @Input() emptyFeedString = 'The feed is empty';
  @Input() emptyFeedDetailsString = 'Items will appear here.';
  @Output() getNextPage: EventEmitter<void> = new EventEmitter();
  @Output() feedInteraction: EventEmitter<void> = new EventEmitter();

  readonly unsubscribe = new Subject<void>();

  @HostListener('click')
  onClick(): void {
    this.feedInteraction.emit();
  }

  constructor(private scrollDispatcher: ScrollDispatcher) { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    this.scrollDispatcher.scrolled().pipe(
      takeUntil(this.unsubscribe),
      throttleTime(10),
      tap(() => this.feedInteraction.emit()),
      filter(() => this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength() && !this.loading)
    ).subscribe(() => this.getNextPage.emit());
  }
}
