import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@dashboard/env';
import { Observable } from 'rxjs';
import { Pagination } from '../models';

export interface ContextualRole {
  role: {
    id: string;
    key?: string;
    name?: string;
    enterprise?: {
      id: string;
    }
  };
  context: {
    path?: string[];
  };
  revoked?: string;
}

export interface Role {
  id: string;
  enterprise: {
    id: string;
  };
  key?: string;
  name?: string;
  description?: string;
}

export interface AccountPermissionResponse {
  id: string;
  assignments?: ContextualRole[];
  grants: {
    action: string;
    path: string[];
  }[];
}

export interface EnterpriseRolesResponse {
  data: Role[];
  pagination: Pagination;
}

export enum SystemGeneratedRole {
  EnterpriseOwner = 'enterprise-owner'
}

export interface Permission {
  name: string;
  granted: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private api = `${environment.DASHBOARD_APP_API_V2}/permission`;

  constructor(private http: HttpClient) {}

  getPermissionByAccountId(accountId: string): Observable<AccountPermissionResponse> {
    return this.http.get<AccountPermissionResponse>(`${this.api}/accounts/${accountId}`);
  }

  revokeRole(accountId: string, roleId: string, contextPath: string): Observable<ContextualRole> {
    return this.http.delete<ContextualRole>(`${this.api}/accounts/${accountId}/roles/${roleId}/contexts/${contextPath}`);
  }

  addRole(accountId: string, role: Role, contextPaths: string[] = []):  Observable<ContextualRole> {
    return this.http.post<ContextualRole>(`${this.api}/accounts/${accountId}/roles`, {
      role: {
        id: role.id,
        key: role.key
      },
      context: {
        path: contextPaths
      }
    });
  }

  getEnterpriseRoles(enterpriseId: string): Observable<EnterpriseRolesResponse> {
    return this.http.get<EnterpriseRolesResponse>(`${this.api}/enterprises/${enterpriseId}/roles`);
  }

  getPermissionsById(): Observable<Permission[]> {
    return this.http.get<Permission[]>(`${this.api}/account/ui`);
  }
}
